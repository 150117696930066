import React, { useState } from "react";

import axios from "axios";

import successIcon from "../../../../../assets/images/success.png";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { withProfile } from "../../../../../contexts/profileContext";

const PaymentStatus = ({ validatedProfile }) => {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    console.log("validatedProfile", validatedProfile);
    const fetchPaymentStatus = async () => {
      const order_id = { order_id: validatedProfile.payment_method_id };

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/CheckStatus`, order_id, { headers: { "Content-Type": "application/json" } });

        console.log("CheckStatus response", response.data);
        if (response.status === 200) {
          setPaymentStatus(response.data.order_status);
        }
      } catch (error) {
        alert("An error occurred during payment submission.");
      }
    };
    fetchPaymentStatus();
  }, [validatedProfile.transecId]);

  return (
    <div className="checkout_div">
      <div className="checkout_div_inner">
        {paymentStatus === "PAID" && (
          <div className="plan_div_heading">
            <img src={successIcon} alt="success icon" className="card_image" />
            <p className="heading_title">Order Completed</p>
          </div>
        )}
        {paymentStatus === "FAILED" && (
          <div className="plan_div_heading">
            <img src={successIcon} alt="success icon" className="card_image" />
            <p className="heading_title">Order Failed</p>
          
          </div>
        )}
      </div>
    </div>
  );
};

export default withProfile(PaymentStatus);
