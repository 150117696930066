import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/30x30.png";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";

import ProfileMenu from "./../Header/HeaderIcon/ProfileMenu";
import NotificationDropdown from "./../Header/HeaderIcon/NotificationDropdown";
import Quickcreate from "./HeaderIcon/Quickcreate";
import MyCompany from "./HeaderIcon/MyCompany";
import { withProfile } from "../../contexts/profileContext";
import { Input } from "antd";

const HeaderSmall = ({ handleButtonClick, mainTableData, handleSearch }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const pathWithoutSlash = currentPath.slice(1);
  const pathnamecapital = (pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1)).split("/")[0];

  const [DataLength, setDataLength] = useState();

  useEffect(() => {
    if (mainTableData) {
      const dataForCurrentPath = mainTableData[pathWithoutSlash] || [];
      setDataLength(dataForCurrentPath.length);
    }
  }, [pathWithoutSlash, mainTableData]);

  const showPathRowDataLength = ["/banking", "/sales/excel", "/purchase", "/ledgerbulk","/item/excel","/ledgermaster","/item","/autosale","/autoexpenses"];
  const masterPathsToCheckMaster = ["/ledgermaster", "/item"];

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="header-left">
            <div className="navbar-brand-box-small text-center header-icon-pd">
              <Link to="/" className="logo logo-light">
                <span>
                  <img className="navbar-brand-box-small-img" src={logolight} alt="logo-light" height="24" />
                </span>
              </Link>
            </div>
            <div className="sidebar-togal-div header-icon-pd">
              <span onClick={handleButtonClick} type="button" className="sidebar-togal-btn">
                <MenuOutlined className="icon-header m-7" />
              </span>
            </div>
            <div className="pathnamecapital">
              <p>{pathnamecapital}</p>
              {showPathRowDataLength.includes(currentPath) && <span className="main_table_data-length">{DataLength}</span>}
            </div>

            {masterPathsToCheckMaster.includes(currentPath) && (
              <div className="header-search-div">
                <form className="app-search">
                  <div className="position-relative">
                    <Input
                      className="header_search_input"
                      type="text"
                      placeholder="Search..."
                      prefix={<SearchOutlined />}
                      onChange={(e) => {
                        if (pathWithoutSlash === "item" || pathWithoutSlash === "ledgermaster") {
                          handleSearch(e);
                        } 
                      }}
                    />
                  </div>
                </form>
              </div>
            ) }
          </div>

          <div className="header-right">
            <Quickcreate />
            <NotificationDropdown />
            <MyCompany />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withProfile(HeaderSmall);
