import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import SidebarData from "./SidebarData";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { withRole } from "../../contexts/roleContext";
import { withProfile } from "../../contexts/profileContext";

const Sidebar = ({ role, validatedProfile }) => {
    
    const [openDropdownKey, setOpenDropdownKey] = useState(null);

    const currentPath = useLocation().pathname;

    const handleSubMenuToggle = (key) => {
        setOpenDropdownKey((prevKey) => (prevKey === key ? null : key));
    };
    console.log("validatedProfile", validatedProfile);
    return (
        <div className="vertical-menu-big">
            <div id="sidebar-menu">
                <ul className=" list-unstyled" id="side-menu-item">
                    {SidebarData.map((item, key) => {
                        let shouldRenderRoute = true;
                        let matchingRoleKey = Object.keys(role).find((rolekey) => item.url === rolekey);
                        if (matchingRoleKey) {
                            if (role[matchingRoleKey] === null && validatedProfile.userType === "S") {
                                shouldRenderRoute = false;
                            }
                        }

                        if (shouldRenderRoute) {
                            return (
                                <React.Fragment key={key}>
                                    <li
                                        id="side-menu-itemp-li"
                                        className={
                                            currentPath === item.url ||
                                            (item.subItem && item.subItem.some((subitem) => currentPath === subitem.link))
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <Link
                                            to={item.url}
                                            onClick={item.subItem ? () => handleSubMenuToggle(key) : null}
                                            className="itemp-a"
                                        >
                                            <div className="sidebar_icon_title_div">
                                                {item.icon}
                                                <span className="sidebar_icon_title_span">{item.label}</span>
                                            </div>
                                            {item.subItem && (
                                                <i className={`arrow-icon ${openDropdownKey === key ? "rotate-up" : "rotate-down"}`}></i>
                                            )}
                                        </Link>

                                        {item.subItem && openDropdownKey === key && (
                                            <ul className="sub-menu">
                                                {item.subItem.map((subMenuItem, subKey) => {
                                                    let matchingRoleKey = Object.keys(role).find((rolekey) => subMenuItem.link === rolekey);
                                                    if (matchingRoleKey && validatedProfile.userType === "S") {
                                                        if (role[matchingRoleKey] !== null) {
                                                            return (
                                                                <li key={subKey}>
                                                                    <Link
                                                                        to={subMenuItem.link}
                                                                        className={`${
                                                                            subMenuItem.subMenu ? "has-arrow waves-effect" : "waves-effect"
                                                                        } ${
                                                                            subMenuItem.link === currentPath ? "active" : ""
                                                                        } sub-item-hover`}
                                                                    >
                                                                        {subMenuItem.sublabel}
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    } else {
                                                        if (
                                                            validatedProfile.userType === "S" &&
                                                            (subMenuItem.link === "/my-plan" ||
                                                                subMenuItem.link === "/users" ||
                                                                subMenuItem.link === "/refer-earn" ||
                                                                subMenuItem.link === "/role-management")
                                                        ) {
                                                            return;
                                                        }
                                                        return (
                                                            <li key={subKey}>
                                                                <Link
                                                                    to={subMenuItem.link}
                                                                    className={`${
                                                                        subMenuItem.subMenu ? "has-arrow waves-effect" : "waves-effect"
                                                                    } ${subMenuItem.link === currentPath ? "active" : ""} sub-item-hover`}
                                                                >
                                                                    {subMenuItem.sublabel}
                                                                </Link>
                                                            </li>
                                                        );
                                                    }
                                                })}
                                            </ul>
                                        )}
                                    </li>
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </ul>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    location: PropTypes.object,
};

export default withRouter(withProfile(withRole(Sidebar)));
