import React, { useState } from "react";
import { withProfile } from "../../../contexts/profileContext";
// import logoDark from "../../../assets/images/logo-dark.png";
import logoDark from "../../../assets/images/45x45.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Spinner from "../../Common/Spinner/Spinner";
import { Input } from "antd";

const SignInWithOtp = ({ setValidatedProfile }) => {
    const [username, setUsername] = useState();
    const [OTP, setOTP] = useState();
    const [showElement, setshowElement] = useState(true); // New state for spinner
    const [isSpinner, setIsSpinner] = useState(false); // New state for spinner
    const navigate = useNavigate();

    const validateInput = (value) => {
        const mobileRegex = /^\d{10}$/; // Regex to match 10-digit mobile number
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex

        if (mobileRegex.test(value)) {
            setUsername(value);
        } else if (emailRegex.test(value)) {
            setUsername(value);
        } else {
            setUsername(value);
        }
    };

    const handleSendOTP = async (e) => {
        e.preventDefault();
        setIsSpinner(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-otp`, {
                identifier: username,
                session_type: "WEB",
            });
            // console.log("response", response);
            console.log("response", response);
            if (response.data.identifier_user === true) {
                setIsSpinner(false);
                alert("You are register with  Email/Mobile and password please login by Email/Mobile");
            } else if (response.data.sessionActive === true) {
                setIsSpinner(false);
                var userConfirmed = window.confirm(
                    "You are already logged in on another PC or laptop. You can only log in on one device at a time. Do you want to signout in other Devices?"
                );
                if (userConfirmed) {
                    try {
                        const token = response.data.token;

                        const responce = await axios.post(
                            `${process.env.REACT_APP_API_URL}/signout`,
                            { identifier: username, session_type: "WEB" },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                        if (responce.status === 200) {
                            const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-otp`, {
                                identifier: username,
                                session_type: "WEB",
                            });

                            if (response.status === 200) {
                                setshowElement(false);
                            }
                        }
                    } catch (error) {
                        console.log("error", error);
                        alert(error.response.data.message);
                    }
                }
            } else if (response.data.send_status === true) {
                console.log("send_status true");
                setshowElement(false);
            }

            setIsSpinner(false);
        } catch (error) {
            setIsSpinner(false);
            console.log("error:", error);
            alert(error.response.data.message);
        }
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        setIsSpinner(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/verify-otp`,

                {
                    identifier: username,
                    otp: OTP,
                    session_type: "WEB",
                }
            );

            setValidatedProfile(response.data);
            setIsSpinner(false);
            navigate("/");
        } catch (error) {
            setIsSpinner(false);
            alert(error.response.data.message);
        }
    };

    return (
        <>
            <Spinner isSpinner={isSpinner} />
            <div className="bg-overlay"></div>
            <div className="auth-bg">
                <div className="auth-page">
                    <div>
                        <div className="titel">
                            <img src={logoDark} alt="logo" />
                            <p className="titel_text">Tax-link</p>
                        </div>
                        <h4 className="text-muted mt-2" style={{ fontSize: 18 }}>
                            Welcome
                        </h4>
                    </div>
                    <div className="text-left">
                        <form action="#">
                            {showElement ? (
                                <div className="login_page_input_div">
                                    <label className="form-label" htmlFor="Mobile/Email">
                                        Mobile/Email<b className="text-danger">*</b>
                                    </label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="Mobile/Email"
                                        placeholder="Enter Mobile/Email"
                                        required
                                        value={username}
                                        onChange={(e) => {
                                            validateInput(e.target.value); // Call the validation function
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="login_page_input_div">
                                    <label className="form-label" htmlFor="OTP">
                                        Enter OTP<b className="text-danger">*</b>
                                    </label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="OTP"
                                        placeholder="Enter OTP"
                                        required
                                        value={OTP || ""}
                                        onChange={(e) => setOTP(e.target.value)}
                                    />
                                </div>
                            )}
                            <div className="forgot_div">
                                <Link className="text-primary" to="/login">
                                    Sign in with password
                                </Link>

                                {!showElement && (
                                    <Link className="text-primary" onClick={handleSendOTP}>
                                        Send OTP again
                                    </Link>
                                )}
                            </div>
                            {showElement ? (
                                <div className="mt-3">
                                    <button className="btn btn-primary w-100" type="submit" onClick={handleSendOTP}>
                                        Get OTP
                                    </button>
                                </div>
                            ) : (
                                <div className="mt-3">
                                    <button className="btn btn-primary w-100" type="submit" onClick={handleVerifyOTP}>
                                        Submit
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>

                    <div className="text-center mt-3">
                        <span className="text-muted">
                            Don't have an account ?{" "}
                            <Link className="text-primary" to="/register">
                                Register
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withProfile(SignInWithOtp);
