import React, { useState, useEffect } from "react";
import TableAll from "../../../../Layout/MainTable/Table";

const AddCompany = () => {
    return (
        <>
            <div className="page-content">
                <TableAll />
            </div>
        </>
    );
};

export default AddCompany;
