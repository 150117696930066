import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { Modal, Steps, Select, Radio, Checkbox, DatePicker } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../../Common/Spinner/Spinner";
import axios from "axios";
import { withProfile } from "../../../../contexts/profileContext";
import dayjs from "dayjs";

const steps = [
    {
        title: "",
        content: "First-content",
    },
    {
        title: "",
        content: "Second-content",
    },
];

const AutoSaleForm = ({ validatedProfile, fetchData, name, modal2Open, handleOkClick, handleCancel }) => {
    const [current, setCurrent] = useState(0);
    const [isSpinner, setIsSpinner] = useState(false); // New state for spinner
    const [radioChange, setRadioChange] = useState(""); // New state for radio change
    const [isFormEnabled, setIsFormEnabled] = useState(true);
    const [ledgerOptions, setLedgerOptions] = useState([]);
    const [livestockFromDb, setlivestockFromDb] = useState([]);
    const [showPurchaseDates, setShowPurchaseDates] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const pathWithoutSlash = location.pathname.replace("/", "").split("/")[0];

    const [formData, setFormData] = useState({
        voucherType: "SALES",
        purchase_from: "",
        purchase_to: "",
        sales_from: "",
        sales_to: "",
        vchNo: "",
        partyName: "",
    });
    const [profit, setprofit] = useState();

    const [itemList, setItemList] = useState([
        {
            itemName: "",
            qty: "",
            salesRate: "",
            totalAmount: "",
        },
    ]);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        getLedgerOptions();
    }, [radioChange === "ALL_ITEMS"]);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current); // Clear interval on component unmount
        };
    }, []);

    const getLedgerOptions = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/maintabledata`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
            });

            if (response.status === 200) {
                let { ledgermaster } = response.data.results;
                // console.log("ledgermaster", ledgermaster);
                // const uniqueOptions = new Set(ledgermaster.filter((item) => item.under === "Bank Accounts").map((item) => item.name));
                const uniqueOptions = new Set(ledgermaster.map((item) => item.name));

                const sortedLedgerOptions = Array.from(uniqueOptions).sort(); // Convert set to array and sort

                setLedgerOptions(sortedLedgerOptions);
                // setLedgerOptions(["Cash", ...Array.from(uniqueOptions)]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchDatalivestock = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/maintabledata`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
            });

            if (response.status === 200) {
                let { livestock } = response.data.results;

                if (livestock[0].flag === "PS") {
                    // if (livestock[0].flag !== "PS") {
                    clearInterval(intervalRef.current);

                    livestock = JSON.parse(livestock[0].data_in_json);
                    setlivestockFromDb(livestock);
                    // console.log("livestock", livestock);
                    setCurrent(current + 1);
                    setIsFormEnabled(false);
                    setIsSpinner(false);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const intervalRef = useRef(null);

    const itemListWithRate = async () => {
        setIsSpinner(true);
        try {
            const insertData = {
                salesfromdate: formData.sales_from,
                saletodate: formData.sales_to,
                flag: "PS",
            };

            // Add purchase dates only if both are provided
            if (formData.purchase_from && formData.purchase_to) {
                insertData.purchasefromdate = formData.purchase_from;
                insertData.purchasetodate = formData.purchase_to;
            }

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/livestockupdate`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
                insertData: insertData, // Pass the constructed insertData object
                tablename: "livestockreq",
            });

            if (response.status === 200) {
                fetchDatalivestock(); // Initial fetch

                // Set interval to call fetchDatalivestock every 5 seconds
                intervalRef.current = setInterval(fetchDatalivestock, 3000);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlFormDataChange = (name, value) => {
        setFormData((prevFormData) => {
            let updatedFormData = { ...prevFormData, [name]: value };

            const { purchase_from, purchase_to, sales_from, sales_to } = updatedFormData;

            // Validation logic
            if (name === "purchase_to" && new Date(value) < new Date(purchase_from)) {
                alert("Purchase To date cannot be earlier than Purchase From date.");
                updatedFormData.purchase_to = null;
            }

            if (name === "sales_from" && new Date(value) < new Date(purchase_from)) {
                alert("Sales From date cannot be earlier than Purchase From date.");
                updatedFormData.sales_from = null;
            }

            if (name === "sales_to" && new Date(value) < new Date(purchase_to)) {
                alert("Sales To date cannot be earlier than Purchase To date.");
                updatedFormData.sales_to = null;
            }

            if (name === "purchase_from" && sales_from && new Date(value) > new Date(sales_from)) {
                alert("Purchase From date cannot be later than Sales From date.");
                updatedFormData.purchase_from = null;
            }

            if (name === "purchase_to" && sales_to && new Date(value) > new Date(sales_to)) {
                alert("Purchase To date cannot be later than Sales To date.");
                updatedFormData.purchase_to = null;
            }

            return updatedFormData;
        });
    };

    const handlVchFormDataChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevFormData) => {
            let updatedFormData = { ...prevFormData, [name]: value };
            if (name === "vchNo") {
                updatedFormData.vchNo = value;
            }
            if (name === "voucherType") {
                updatedFormData.voucherType = value;
            }

            return updatedFormData;
        });
    };
    const handleItemListChange = (index, e) => {
        const { name, value } = e.target;
        const newItemList = [...itemList];

        if (name === "itemName") {
            newItemList[index][name] = value;

            // Get the selected livestock data
            const selectedLivestock = livestockFromDb.find((item) => item.name === value);
            if (selectedLivestock) {
                // Set qty placeholder and salesRate value
                newItemList[index].qty = selectedLivestock.quantity; // Placeholder will be set in the JSX
                newItemList[index].salesRate = selectedLivestock.rate; // Set initial value for salesRate
            }
        }

        if (name === "qty") {
            // Ensure qty is between 0 and livestock quantity
            const selectedLivestock = livestockFromDb.find((item) => item.name === newItemList[index].itemName);
            if (selectedLivestock) {
                newItemList[index][name] = Math.max(0, Math.min(value, selectedLivestock.quantity));
            }
        }

        if (name === "salesRate") {
            // Ensure salesRate is greater than or equal to livestock rate
            const selectedLivestock = livestockFromDb.find((item) => item.name === newItemList[index].itemName);
            if (selectedLivestock) {
                newItemList[index][name] = Math.max(selectedLivestock.rate, value);
            }
        }

        // Calculate totalAmount
        const qty = newItemList[index]["qty"] || 0;
        const rate = newItemList[index]["salesRate"] || 0;
        newItemList[index]["totalAmount"] = qty * rate;

        setItemList(newItemList);
    };

    const addItemCustom = () => {
        // Add a new empty item to the itemList
        const newItem = {
            itemName: "",
            qty: "",
            salesRate: "",
            totalAmount: "",
        };

        const updatedItemList = [...itemList, newItem];
        setItemList(updatedItemList);
    };

    const handleProfitChange = (e) => {
        if (e.target.value <= 0) {
            setprofit(0);
        } else if (e.target.value >= 200) {
            setprofit(200);
        } else if (e.target.value > 0 && e.target.value < 200) {
            setprofit(e.target.value);
        }
    };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadioChange(value);
    };

    const purchaseDateCheckbox = (e) => {
        setShowPurchaseDates(e.target.checked);
        formData.purchase_from = 0;
        formData.purchase_to = 0;
    };

    const submitData = async () => {
        let updatedItemList;
        const randomInt = Math.floor(Math.random() * 900) + 100;
        let data;
        if (radioChange === "ALL_ITEMS") {
            updatedItemList = [
                {
                    itemName: "ALL_ITEMS",
                    qty: null,
                    salesRate: null,
                    totalAmount: null,
                    profit: profit,
                    flag_for_item: "ALL",
                },
            ];

            data = {
                autosaledata: updatedItemList.map((item) => ({
                    voucher_type: formData.voucherType,
                    purchase_from: formData.purchase_from,
                    purchase_to: formData.purchase_to,
                    sales_from: formData.sales_from,
                    sales_to: formData.sales_to,
                    vch_no: formData.vchNo,
                    party_name: formData.partyName,
                    ...item,
                })),
                autosale: [{ name_of_autosale: `Autosale ${randomInt}` }],
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
            };
        }
        if (radioChange === "CUSTOM_ITEMS") {
            updatedItemList = itemList.map((item) => ({
                itemName: item.itemName,
                qty: item.qty,
                salesRate: item.salesRate,
                totalAmount: item.qty * item.salesRate,
                flag_for_item: "CUS",
            }));

            data = {
                autosaledata: updatedItemList
                    .filter((item) => item.itemName && item.qty && item.salesRate) // Filter out items that don't meet the condition
                    .map((item) => ({
                        voucher_type: formData.voucherType,
                        purchase_from: formData.purchase_from,
                        purchase_to: formData.purchase_to,
                        sales_from: formData.sales_from,
                        sales_to: formData.sales_to,
                        vch_no: formData.vchNo,
                        party_name: formData.partyName,
                        ...item,
                    })),
                autosale: [{ name_of_autosale: `Autosale ${randomInt}` }],
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
            };
        }
        console.log("data autosale", data);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertfileapi`, data, {
                headers: { "Content-Type": "application/json" },
            });

            fetchData();
            navigate("/transactionstable", {
                state: { key: response.data.result.key, pathWithoutSlash },
            });
            let modal2Open = false;
        } catch (error) {
            console.error("Submission error:", error);
        }
    };
    // console.log("livestockFromDb", livestockFromDb);
    return (
        <>
            <Modal centered open={modal2Open} onOk={handleOkClick} onCancel={handleCancel} className="autosaleModel">
                <Spinner isSpinner={isSpinner} />

                <p className="autosale_model_heading">{`Add ${name.charAt(0).toUpperCase()}${name.slice(1)}`}</p>
                <Steps current={current} className="autosaleModel_steps">
                    {steps.map((item) => (
                        <Steps.Step key={item.title} title={item.title} />
                    ))}
                </Steps>

                <div className="autosale_Body">
                    {current === 0 && (
                        <div className="body_page_0 body_page">
                            <div className="page_inner">
                                <div className="radio_btn_div shadow_div">
                                    <Radio.Group value={radioChange} onChange={handleRadioChange} className="radio_btn">
                                        <Radio className="radio_btn_item" value="CUSTOM_ITEMS">
                                            CUSTOM ITEMS
                                        </Radio>
                                        <Radio className="radio_btn_item" value="ALL_ITEMS">
                                            ALL ITEMS
                                        </Radio>
                                    </Radio.Group>
                                </div>

                                <div className="shadow_div">
                                    {showPurchaseDates && (
                                        <div className="purchase_date_element  is_Form_Element">
                                            <div className="name">
                                                <label htmlFor="purchase_from" className="d-block">
                                                    purchase from
                                                </label>
                                                <DatePicker
                                                    className="autosale_input_eliment"
                                                    id="purchase_from"
                                                    value={formData.purchase_from ? dayjs(formData.purchase_from) : null}
                                                    onChange={(date, dateString) => handlFormDataChange("purchase_from", dateString)}
                                                    placeholder="Purchase From Date"
                                                />
                                            </div>
                                            <div className="name ">
                                                <label htmlFor="purchase_to" className="d-block">
                                                    purchase to
                                                </label>
                                                <DatePicker
                                                    className="autosale_input_eliment"
                                                    id="purchase_to"
                                                    value={formData.purchase_to ? dayjs(formData.purchase_to) : null}
                                                    onChange={(date, dateString) => handlFormDataChange("purchase_to", dateString)}
                                                    placeholder="Purchase To Date"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="purchase_date_element  is_Form_Element">
                                        <div className="name">
                                            <label htmlFor="sales_from" className="d-block">
                                                sales from<sup className="text-danger">*</sup>
                                            </label>
                                            <DatePicker
                                                className="autosale_input_eliment"
                                                id="sales_from"
                                                value={formData.sales_from ? dayjs(formData.sales_from) : null}
                                                onChange={(date, dateString) => handlFormDataChange("sales_from", dateString)}
                                                placeholder="Sales From Date"
                                            />
                                        </div>
                                        <div className="name">
                                            <label htmlFor="sales_to" className="d-block">
                                                sales to<sup className="text-danger">*</sup>
                                            </label>
                                            <DatePicker
                                                className="autosale_input_eliment"
                                                id="sales_to"
                                                value={formData.sales_to ? dayjs(formData.sales_to) : null}
                                                onChange={(date, dateString) => handlFormDataChange("sales_to", dateString)}
                                                placeholder="Sales To Date"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {current === 1 && (
                        <div className="body_page_1 body_page">
                            <div className="page_inner">
                                <div className="shadow_div">
                                    <div className="voucher_Type  is_Form_Element">
                                        <div className="name">
                                            <label htmlFor="vchNo" className="d-block">
                                                Vch No.<sup className="text-danger">*</sup>
                                            </label>
                                            <input
                                                className="autosale_input_eliment"
                                                type="text"
                                                id="vchNo"
                                                name="vchNo"
                                                value={formData.vchNo}
                                                onChange={handlVchFormDataChange}
                                            />
                                        </div>
                                        <div className="name ">
                                            <label htmlFor="partyName" className="d-block">
                                                Party Name<sup className="text-danger">*</sup>
                                            </label>
                                            <Select
                                                showSearch
                                                className="autosale_input_eliment_Select"
                                                name="partyName"
                                                value={formData.partyName}
                                                dropdownStyle={{ zIndex: 9999 }}
                                                onChange={(value) => setFormData((prevForm) => ({ ...prevForm, partyName: value }))}
                                            >
                                                {ledgerOptions.map((item, idx) => (
                                                    <Select.Option key={idx} value={item}>
                                                        {item}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="voucher_Type  is_Form_Element">
                                        <div className="name">
                                            <label htmlFor="voucherType" className="d-block">
                                                Voucher Type<sup className="text-danger">*</sup>
                                            </label>
                                            <input
                                                className="autosale_input_eliment"
                                                type="text"
                                                id="voucherType"
                                                name="voucherType"
                                                value={formData.voucherType}
                                                onChange={handlVchFormDataChange}
                                                disabled={true}
                                            />
                                        </div>

                                        <div className={`name ${radioChange !== "ALL_ITEMS" ? "invisible" : ""}`}>
                                            <label htmlFor="profit" className="d-block">
                                                Profit %<sup className="text-danger">*</sup>
                                            </label>
                                            <input
                                                className="autosale_input_eliment"
                                                type="number"
                                                id="profit"
                                                name="profit"
                                                min={0}
                                                max={200}
                                                value={profit || 0}
                                                onChange={handleProfitChange}
                                                disabled={radioChange !== "ALL_ITEMS"}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="custom_item_div">
                                    {radioChange === "CUSTOM_ITEMS" && (
                                        <>
                                            <table className="custom_item_table">
                                                <thead>
                                                    <tr>
                                                        {["Srno", "Item Name", "Quantity", "Sales Rate", "Total Amount"].map(
                                                            (header, idx) => (
                                                                <th key={idx} className="text-center">
                                                                    {header}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="tbody">
                                                    {itemList.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{index + 1}</td>

                                                            <td>
                                                                <Select
                                                                    showSearch
                                                                    className="table_item_element"
                                                                    id={`itemName${index}`}
                                                                    name="itemName"
                                                                    value={item.itemName}
                                                                    dropdownStyle={{ zIndex: 9999 }}
                                                                    onChange={(value) =>
                                                                        handleItemListChange(index, { target: { name: "itemName", value } })
                                                                    }
                                                                    disabled={isFormEnabled}
                                                                >
                                                                    {livestockFromDb.map((dropdownItem, idx) => (
                                                                        <Select.Option key={idx} value={dropdownItem.name}>
                                                                            {dropdownItem.name}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="table_input_element"
                                                                    type="number"
                                                                    name="qty"
                                                                    placeholder={item.qty || "Quantity"}
                                                                    value={item.qty}
                                                                    onChange={(e) => handleItemListChange(index, e)}
                                                                    disabled={isFormEnabled}
                                                                />
                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="table_input_element"
                                                                    type="number"
                                                                    name="salesRate"
                                                                    value={item.salesRate}
                                                                    onChange={(e) => handleItemListChange(index, e)}
                                                                    disabled={isFormEnabled}
                                                                />
                                                            </td>

                                                            <td>
                                                                <input
                                                                    className="table_input_element"
                                                                    type="text"
                                                                    id={`totalAmount${index}`}
                                                                    name="totalAmount"
                                                                    value={item.totalAmount || 0}
                                                                    readOnly
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="autosale_btn_div">
                        <div className="Next_btn_div">
                            {current === 0 && (
                                <Button
                                    className="autosale_btn"
                                    color="primary"
                                    onClick={itemListWithRate}
                                    disabled={
                                        !formData.sales_from || !formData.sales_to || radioChange === "ALL_ITEMS" || radioChange === ""
                                    }
                                    style={{
                                        cursor:
                                            !formData.sales_from || !formData.sales_to || radioChange === "ALL_ITEMS" || radioChange === ""
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                >
                                    Get Stock
                                </Button>
                            )}
                            {current < steps.length - 1 && (
                                <Button
                                    className="autosale_btn"
                                    type="primary"
                                    onClick={next}
                                    disabled={!formData.sales_from || !formData.sales_to || radioChange === ""}
                                    style={{
                                        cursor:
                                            !formData.sales_from || !formData.sales_to || radioChange === "" ? "not-allowed" : "pointer",
                                    }}
                                >
                                    Next
                                </Button>
                            )}
                            {current === steps.length - 1 && (
                                <Button
                                    className="autosale_btn"
                                    color="primary"
                                    onClick={submitData}
                                    disabled={!formData.vchNo || !formData.partyName}
                                >
                                    Submit
                                </Button>
                            )}
                            {current > 0 && (
                                <Button className="autosale_btn" onClick={prev}>
                                    Previous
                                </Button>
                            )}
                        </div>
                        {radioChange === "CUSTOM_ITEMS" && current === 1 && (
                            <div className="add_item_div autosale_btn">
                                <Button
                                    className="add_item_btn"
                                    id="addItem"
                                    color="primary"
                                    onClick={addItemCustom}
                                    disabled={isFormEnabled}
                                >
                                    Add Item
                                </Button>
                            </div>
                        )}
                        {current === 0 && (
                            <Checkbox className="purchase_checkbox" checked={showPurchaseDates} onChange={purchaseDateCheckbox}>
                                Set Purchase Date
                            </Checkbox>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default withProfile(AutoSaleForm);
