import axios from "axios";
import React, { useEffect } from "react";
import TableAll from "../../../../Layout/MainTable/Table";

const PurchaseExcel = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

export default PurchaseExcel;
