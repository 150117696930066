import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { authProtectedRoutes, publicRoutes, subUserAuthProtectedRoutes } from "./routes";
import AuthProtected from "./AuthProtected";
import Layout from "../Layout";
import { withRole } from "../contexts/roleContext";
import { ProfileContext } from "../contexts/profileContext";
import axios from "axios";
import Cookies from "js-cookie";

const Index = ({ role }) => {
    const { validatedProfile, setValidatedProfile } = useContext(ProfileContext);
    const navigate = useNavigate();

    let location = useLocation();
    const pathWithoutSlash = location.pathname.slice(1);

    const handleClick = async () => {
        if (validatedProfile) {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/check-session`,
                    {
                        userId: validatedProfile.userid,
                        session_type: "WEB",
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${validatedProfile.token}`,
                        },
                    }
                );

                if (response.data.session === false) {
                    Cookies.remove("validatedProfile");
                    localStorage.removeItem("role");
                    localStorage.removeItem("roleWithoutSlash");
                    setValidatedProfile("");
                    navigate("/login");
                }
            } catch (error) {
                console.error("Error during API call:", error);
            }
        }
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") {
                handleClick();
            }
        };

        if (validatedProfile) {
            handleClick();
            document.addEventListener("visibilitychange", handleVisibilityChange);
            document.addEventListener("click", handleClick);
        }

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            document.removeEventListener("click", handleClick);
        };
    }, [validatedProfile, handleClick]);

    return (
        <Routes>
            {validatedProfile?.userType === "P" ? (
                <Route>
                    {authProtectedRoutes?.map((route, idx) => {
                        return (
                            <Route
                                path={route.path}
                                element={
                                    <AuthProtected path={route.path}>
                                        <Layout>{route.component}</Layout>
                                    </AuthProtected>
                                }
                                key={idx}
                                exact={true}
                            />
                        );
                    })}
                </Route>
            ) : (
                <Route>
                    {subUserAuthProtectedRoutes?.map((route, idx) => {
                        const matchingRoleKey = role ? Object.keys(role).find((rolekey) => route.path === rolekey) : null;

                        if (matchingRoleKey) {
                            if (role[matchingRoleKey] !== null) {
                                return (
                                    <Route
                                        path={route.path}
                                        element={
                                            <AuthProtected path={route.path}>
                                                <Layout>{route.component}</Layout>
                                            </AuthProtected>
                                        }
                                        key={idx}
                                        exact={true}
                                    />
                                );
                            }
                        } else {
                            return (
                                <Route
                                    path={route.path}
                                    element={
                                        <AuthProtected path={route.path}>
                                            <Layout>{route.component}</Layout>
                                        </AuthProtected>
                                    }
                                    key={idx}
                                    exact={true}
                                />
                            );
                        }
                    })}
                </Route>
            )}

            <Route>
                {publicRoutes.map((route, idx) => (
                    <Route path={route.path} element={route.component} key={idx} exact={true} />
                ))}
            </Route>
        </Routes>
    );
};

export default withRole(Index);
