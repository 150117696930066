import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logoDark from "../../../assets/images/45x45.png";
import bcrypt from "bcryptjs";
import Spinner from "../../Common/Spinner/Spinner";
import { Input } from "antd";

const Register = () => {
    const navigate = useNavigate();
    const [isSpinner, setIsSpinner] = useState(false); // New state for spinner
    const [formData, setFormData] = useState({
        email: "",
        mobile: "",
        password: "",
        firstName: "",
        lastName: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "parentID" && value === "" ? null : value,
        }));
    };

    const handleSubmit = async (e) => {
        setIsSpinner(true);
        e.preventDefault();
        try {
            const hashedPassword = await hashWithFixedSalt(formData.password);
            const mobileRegex = /^[6-9]\d{9}$/; // Indian mobile number validation
            if (!mobileRegex.test(formData.mobile)) {
                alert("Mobile number must be exactly 10 digits.");
                return;
            }

            const payload = {
                email: formData.email,
                number: formData.mobile,
                password: hashedPassword,
                name: `${formData.firstName} ${formData.lastName}`,

                user_type: "P",
            };
            console.log("payload", payload);

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log("response", response);

            if (response.status === 200) {
                setIsSpinner(false);
                alert("Registration successful!");
                navigate("/login");
            }
        } catch (error) {
            setIsSpinner(false);
            alert(error.response.data.message);
        }

        async function hashWithFixedSalt(password) {
            const fixedSalt = "$2b$10$yourfixedsaltforyourapplication";
            const hashedPassword = await bcrypt.hash(password, fixedSalt);
            return hashedPassword;
        }
    };

    return (
        <>
            <Spinner isSpinner={isSpinner} />
            <div className="bg-overlay"></div>
            <div className="auth-bg">
                <div className="auth-page">
                    <div>
                        <div className="titel">
                            <img src={logoDark} alt="logo" />
                            <p className="titel_text">Tax-link</p>
                        </div>
                        <h4 className="text-muted welcome_text">Free Register</h4>
                        <span className="text-muted">Get your free Tax-link account now.</span>
                    </div>
                    <div className="text-left ">
                        <form action="#" onSubmit={handleSubmit}>
                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="Email">
                                    Email<b className="text-danger">*</b>
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Email"
                                    placeholder="Enter Email"
                                    required
                                    onChange={handleChange}
                                    name="email"
                                />
                            </div>
                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="Mobile">
                                    Mobile<b className="text-danger">*</b>
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Mobile"
                                    placeholder="Enter Mobile Number"
                                    required
                                    onChange={handleChange}
                                    name="mobile"
                                />
                            </div>
                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="FirstName">
                                    First Name<b className="text-danger">*</b>
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="FirstName"
                                    placeholder="Enter First Name"
                                    required
                                    onChange={handleChange}
                                    name="firstName"
                                />
                            </div>
                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="LastName">
                                    Last Name<b className="text-danger">*</b>
                                </label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="LastName"
                                    placeholder="Enter Last Name"
                                    required
                                    onChange={handleChange}
                                    name="lastName"
                                />
                            </div>

                            <div className="login_page_input_div">
                                <label className="form-label" htmlFor="userpassword">
                                    Password<b className="text-danger">*</b>
                                </label>
                                <Input.Password
                                    type="password"
                                    id="userpassword"
                                    onChange={handleChange}
                                    visibilityToggle={true}
                                    name="password"
                                    required
                                    placeholder="Enter Password"
                                />
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="term-conditionCheck" required />
                                <label className="form-check-label fw-normal" htmlFor="term-conditionCheck">
                                    I accept{" "}
                                    <Link to="#" className="text-primary">
                                        Terms and Conditions
                                    </Link>
                                </label>
                            </div>
                            <div className="login_page_input_div">
                                <button className="btn btn-primary w-100" type="submit">
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="text-center login_page_input_div">
                        <span className="text-muted">
                            Already have an account ?{" "}
                            <Link className="text-primary" to="/login">
                                Login
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
