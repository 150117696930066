import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const MyPlan = () => {
  const toggleShow = (e) => {
    e.stopPropagation();
    const showmoreUl = e.currentTarget.parentElement.querySelector(".show-component");
    if (showmoreUl) {
      showmoreUl.classList.toggle("show");
    }
  };

  const navigate = useNavigate();
  const [rightPlanCardBig, setRightPlanCardBig] = useState(true);


  const handleChoosePlan = (planValue) => {
    navigate("/checkout", { state: { planValue: planValue } });
  };
  const communityCard = () => {
    return (
      <>
        <div className="card_title_div">
          <p className="card_title">Community</p>
          {rightPlanCardBig && <span className="card_title_RECOMMENDED">RECOMMENDED</span>}
        </div>
        <span className="card_title_sub">Plan</span>
        <div className="card_feature">
          <ul>
            <li>Rs 10000</li>
            <li>Include 3 user registration</li>
            <li>Include 3 Company Listing</li>
            {rightPlanCardBig && (
              <>
                <li>Unlimited Company Listing</li>
                <li>You can add more users</li>
                <li>Unlimited file upload</li>
              </>
            )}
          </ul>
        </div>
      </>
    );
  };
  const professionalCard = () => {
    return (
      <>
        <p className="card_title">Professional</p>
        <span className="card_title_sub">Plan</span>
        <div className="card_feature">
          <ul>
            <li>Rs 5000</li>
            <li>Include 3 user registration</li>
            <li>Unlimited file upload</li>
            {!rightPlanCardBig && (
              <>
                <li>You can add more users</li>
                <li>You can add more companys</li>
                <li>Unlimited file upload</li>
              </>
            )}
          </ul>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="page-content">
        <div className="my-plan">
          <ul className="my-plan-ul">
            <li className="my-plan-li">
              <p className="show-btn" onClick={toggleShow}>
                View plan <DownOutlined />
              </p>
              <div className="show">
                <div className="plan_div">
                  <div className="plan_div_inner">
                    <div className="plan_div_body">
                      <div className="plan_div_body_inner">
                        {rightPlanCardBig ? (
                          <>
                            <div className="body_left_card card" onClick={() => setRightPlanCardBig(false)}>
                              {professionalCard()}
                              <div className="card_btn_div">
                                <button className="left_card_btn card_btn" onClick={() => handleChoosePlan("5000")}>
                                  Choose Plan
                                </button>
                              </div>
                            </div>

                            <div className="body_right_card card" onClick={() => setRightPlanCardBig(true)}>
                              {communityCard()}
                              <div className="card_btn_div">
                                <button className="right_card_btn card_btn" onClick={() => handleChoosePlan("10000")}>
                                  Choose Plan
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="body_right_card card" onClick={() => setRightPlanCardBig(false)}>
                              {professionalCard()}
                              <div className="card_btn_div">
                                <button className="right_card_btn card_btn" onClick={() => handleChoosePlan("5000")}>
                                  Choose Plan
                                </button>
                              </div>
                            </div>

                            <div className="body_left_card card " onClick={() => setRightPlanCardBig(true)}>
                              {communityCard()}
                              <div className="card_btn_div">
                                <button className="left_card_btn card_btn" onClick={() => handleChoosePlan("10000")}>
                                  Choose Plan
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="my-plan-li">
              <p className="show-btn" onClick={toggleShow}>
                View Payment History <DownOutlined />
              </p>
              <div className="show-component">
                <li>Users & 10 Companies</li>
                <li>Unlimited document process </li>
                <li>INR 1000 for additional User</li>
                <li>Free Support 12 x 6</li>
              </div>
            </li>
            <li className="my-plan-li">
              <p className="show-btn" onClick={toggleShow}>
                View Transaction History <DownOutlined />
              </p>
              <div className="show-component">
                <li>Users & 10 Companies</li>
                <li>Unlimited document process </li>
                <li>INR 1000 for additional User</li>
                <li>Free Support 12 x 6</li>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MyPlan;
