import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Table, Button } from "antd";
import { withProfile } from "../../../../contexts/profileContext";

const Users = ({ validatedProfile }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [userListData, setUserListData] = useState([]);
    const location = useLocation();
    const currentPath = location.pathname.substring(1);

    const navigate = useNavigate();
    useEffect(() => {
        getuserdata();
    }, []);

    const getuserdata = async () => {
        const id = {
            parentUserId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/role-management-details`, id);
            const data = response.data.userDetails;
            // console.log("data", data);
            setUserListData(data);
        } catch (error) {
            console.log("error", error);
        }
    };

    const editRole = (record) => {
        navigate("/adduser", {
            state: { editkey: record.userid },
        });
    };

    const deleteRole = (record, key) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this role?");
        if (!isConfirmed) {
            return;
        }
        let payload = { deleteID: [record.userid] };
        axios
            .post(`${process.env.REACT_APP_API_URL}/delete-subuser-userlist`, payload)
            .then((response) => {
                if (response.status === 200) {
                    getuserdata();
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const columnsmain = [
        {
            title: "Sr No.",
            dataIndex: "srno",
            key: "srno",
            width: 70,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Assigned Roler",
            dataIndex: "assignedRole",
            key: "assignedRole",
        },
        {
            title: "Mobile",
            dataIndex: "mobile",
            key: "mobile",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
                <p>
                    <Button type="link" onClick={() => editRole(record)}>
                        <EditOutlined />
                    </Button>
                    <Button type="link" onClick={() => deleteRole(record)}>
                        <DeleteOutlined className="text-danger" />
                    </Button>
                </p>
            ),
        },
    ];
    const data = [];
    for (let i = 0; i < userListData.length; i++) {
        data.push({
            key: i,
            srno: `${i + 1}`,
            name: userListData[i].user_name,
            email: userListData[i].user_email,
            assignedRole: 32,
            status: "Active",
            mobile: userListData[i].user_mobile,
            userid: userListData[i].user_id,
        });
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    return (
        <>
            <div className="setting-user">
                <div className="user-heading">
                    <ul className="user-heading-ul">
                        <li className={`user-heading-li ${currentPath === "users" ? "active" : ""}`}>
                            <Link className="user-heading-li-a" to="/users">
                                User List
                            </Link>
                        </li>
                        <li className={`user-heading-li  ${currentPath === "user-report" ? "active" : ""}`}>
                            <Link className="user-heading-li-a" to="/user-report">
                                User Report
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="page-content">
                <div className="Users_Div">
                    <Table
                        className="Users_Table"
                        rowSelection={rowSelection}
                        columns={columnsmain}
                        dataSource={data}
                        rowKey="key"
                        pagination={false}
                        scroll={{ y: "65vh" }}
                    />
                </div>
            </div>
        </>
    );
};

// export default Users;
export default withProfile(Users);
