// import React, { useState, useEffect } from "react";
// import { Button, Modal, Radio, Table, Input, Select } from "antd";
// import { withProfile } from "../../../../contexts/profileContext";
// import axios from "axios";
// import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";

// const AutoExpensesForm = ({ name, modal2Open, handleOkClick, handleCancel, mainTableData, validatedProfile, fetchData }) => {
//   const [current, setCurrent] = useState(0);
//   const [ledgerList, setLedgerList] = useState([]);
//   const [ledgerForPartyName, setLedgerForPartyName] = useState([]);
//   const [selectedLedgers, setSelectedLedgers] = useState([]);

//   const [paymentType, setPaymentType] = useState("receipt");
//   const [partyName, setPartyName] = useState("");
//   const [editableTableData, setEditableTableData] = useState([]);

//   useEffect(() => {
//     setEditableTableData([]);
//     if (mainTableData) {
//       const uniqueLedgers = [...new Set(mainTableData.ledgermaster.map((row) => row.name))];
//       setLedgerForPartyName(uniqueLedgers.sort());
//       setLedgerList(uniqueLedgers.map((ledger) => ({ key: ledger, ledger, status: "" })).sort());
//     }
//   }, [mainTableData]);

//   const handlePaymentTypeChange = (e) => {
//     setPaymentType(e.target.value);
//   };

//   const handleLedgerClick = (ledgerName) => {
//     setLedgerList((prevList) => prevList.map((item) => (item.ledger === ledgerName ? { ...item, status: item.status === "Yes" ? "" : "Yes" } : item)));

//     setSelectedLedgers((prevSelected) => (prevSelected.includes(ledgerName) ? prevSelected.filter((name) => name !== ledgerName) : [...prevSelected, ledgerName]));
//   };

//   const getCurrentFinancialYearDates = () => {
//     const today = new Date();
//     const currentYear = today.getFullYear();
//     const currentMonth = today.getMonth(); // 0 = January, 11 = December

//     let startYear, endYear;

//     // Determine the start and end years based on the financial year logic
//     if (currentMonth < 3) {
//       // Jan, Feb, Mar belong to the previous financial year
//       startYear = currentYear - 1;
//       endYear = currentYear;
//     } else {
//       // Apr-Dec belong to the current financial year
//       startYear = currentYear;
//       endYear = currentYear + 1;
//     }

//     // Construct the first and last dates
//     const firstDate = `01/04/${startYear}`;
//     const lastDate = `31/03/${endYear}`;

//     return { firstDate, lastDate };
//   };

//   const { firstDate, lastDate } = getCurrentFinancialYearDates();
//   const performOnSelectLedger = () => {
//     // Prepare data for the second table
//     if (selectedLedgers.length > 0) {
//       const newEditableData = selectedLedgers.map((ledger) => ({
//         key: ledger,
//         selectedLedger: ledger,
//         fromDate: firstDate,
//         toDate: lastDate,
//         fixAmount: "",
//         fixDate: "",
//         totalEntries: "",
//         totalAmount: "",
//         minimumAmount: "",
//         maximumAmount: "",
//         remark: "",
//       }));
//       setEditableTableData(newEditableData);
//       setCurrent((prev) => prev + 1);
//     } else {
//       alert("Select expense");
//     }
//   };
//   const handlePrevious = () => {
//     setCurrent((prev) => prev - 1);
//   };
//   const handleNext = () => {
//     setCurrent((prev) => prev + 1);
//   };
//   const [monthlyEntries, setMonthlyEntries] = useState([]);

//   const handleSubmit = () => {
//     setMonthlyEntries(generateMonthlyEntries(editableTableData, firstDate, lastDate));
//     setCurrent((prev) => prev + 1);
//   };

//   const handleSendData = async () => {
//     const randomInt = Math.floor(Math.random() * 900) + 100;

//     const data = {
//       autoexpensesdata: { expenses_name: monthlyEntries },
//       autoexpenses: { name: `Autoexpenses ${randomInt}`, total_items: monthlyEntries.length },
//       userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
//       companyId: validatedProfile.companyId,
//       vtype: paymentType,
//       partyname: partyName,
//     };

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/autoExpensesUpdate`, data, {
//         headers: { "Content-Type": "application/json" },
//       });
//       fetchData();
//       handleOkClick(); // Close the modal
//       console.log("response", response);
//     } catch (error) {
//       console.error("Submission error:", error);
//     }
//   };

//   const generateMonthlyEntries = (editableTableData, firstDate, lastDate) => {
//     // Validate the financial year dates
//     if (!firstDate || !lastDate) {
//       console.error("Invalid financial year dates.");
//       return [];
//     }

//     const financialStart = new Date(firstDate);
//     const financialEnd = new Date(lastDate);

//     // Helper to get the last day of a month
//     const getLastDayOfMonth = (year, month) => new Date(year, month + 1, 0).getDate();

//     const allEntries = []; // Array to store all the generated entries
//     let idCounter = 1;
//     console.log("editableTableData", editableTableData);
//     editableTableData.forEach((item, rowIndex) => {
//       const { fromDate, toDate, fixDate, fixAmount, remark, key, totalAmount, totalEntries, minimumAmount, maximumAmount } = item;

//       if (fixAmount > 0) {
//         // Ensure fixDate is a valid day of the month
//         const fixDay = parseInt(fixDate, 10); // Convert to a number
//         if (isNaN(fixDay) || fixDay < 1 || fixDay > 31) {
//           console.log(`Invalid fixDate '${fixDate}' for row ${rowIndex + 1}.`);
//           return;
//         }

//         const startMonth = 3; // Start from the 3rd month (0-indexed: March)
//         const startYear = financialStart.getFullYear();

//         // Generate 12 months of entries starting from the 3rd month
//         const entries = Array.from({ length: 12 }, (_, monthOffset) => {
//           const year = startMonth + monthOffset < 12 ? startYear : startYear + 1; // Handle year rollover
//           const month = (startMonth + monthOffset) % 12; // Keep months within 0-11 range

//           // Get the last day of the current month
//           const lastDayOfMonth = getLastDayOfMonth(year, month);

//           // Use the lesser of fixDay or lastDayOfMonth to prevent invalid dates
//           const adjustedDay = Math.min(fixDay, lastDayOfMonth);

//           // Create the entry date
//           const entryDate = new Date(year, month, adjustedDay);

//           // Ensure the generated date is within the financial year
//           if (entryDate < financialStart || entryDate > financialEnd) return null;

//           // Format date to YYYY-MM-DD without timezone issues
//           const formattedDate = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, "0")}-${String(entryDate.getDate()).padStart(2, "0")}`;

//           return {
//             id: idCounter++,
//             flag: "NS",
//             "Sr No": monthOffset + 1, // Reset the "Sr No" for each row
//             date: formattedDate,

//             expenses: key,
//             amount: parseFloat(fixAmount) || 0,
//             remark: remark || "",
//           };
//         }).filter(Boolean); // Filter out null entries

//         allEntries.push(...entries); // Add entries for this row to the allEntries array
//       }

//       if (fixAmount === "" && fixDate) {
//         // Ensure fixDate is a valid day of the month
//         const fixDay = parseInt(fixDate, 10); // Convert to a number
//         if (isNaN(fixDay) || fixDay < 1 || fixDay > 31) {
//           console.log(`Invalid fixDate '${fixDate}' for row ${rowIndex + 1}.`);
//           return;
//         }

//         const startMonth = 3; // Start from the 3rd month (0-indexed: March)
//         const startYear = financialStart.getFullYear();

//         const randomAmounts = [];
//         // Generate totalEntries number of rows with random months
//         const entries = Array.from({ length: totalEntries }, (_, rowIndex) => {
//           // Select a random month within the financial year
//           const randomMonthOffset = Math.floor(Math.random() * 12); // Random month offset between 0 and 11
//           const year = startMonth + rowIndex < 12 ? startYear : startYear + 1;
//           const month = (startMonth + rowIndex) % 12;

//           // Get the last day of the current month
//           const lastDayOfMonth = getLastDayOfMonth(year, month);

//           // Use the lesser of fixDay or lastDayOfMonth to prevent invalid dates
//           const adjustedDay = Math.min(fixDay, lastDayOfMonth);

//           // Create the entry date with the fixed day but random month
//           const entryDate = new Date(year, month, adjustedDay);

//           // Ensure the generated date is within the financial year
//           if (entryDate < financialStart || entryDate > financialEnd) return null;

//           // Divide totalAmount into parts, ensuring each part is within the min and max limits
//           let remainingAmount = totalAmount;
//           const parts = totalEntries;

//           // Generate random parts for each row ensuring it's within min and max limits
//           for (let i = 0; i < parts - 1; i++) {
//             let randomPart = Math.floor(Math.random() * (maximumAmount - minimumAmount + 1)) + minimumAmount;

//             // Ensure random part is positive and valid
//             randomPart = Math.max(randomPart, minimumAmount);
//             randomAmounts.push(randomPart);
//             remainingAmount -= randomPart; // Deduct the generated part from remainingAmount

//             // Ensure the remaining amount doesn't go below 0
//             if (remainingAmount < 0) {
//               remainingAmount = 0;
//             }
//           }

//           // The last part will be the remaining amount, but capped at maximumAmount
//           let lastPart = Math.min(remainingAmount, maximumAmount);

//           // Ensure last part is not negative and is within min and max range
//           lastPart = Math.max(lastPart, minimumAmount);
//           randomAmounts.push(lastPart);

//           // Ensure that no negative amounts are generated in the random amounts
//           randomAmounts.forEach((amount, index) => {
//             randomAmounts[index] = Math.max(amount, minimumAmount); // Prevent negative or too low amounts
//           });

//           // Format date to YYYY-MM-DD without timezone issues
//           const formattedDate = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, "0")}-${String(entryDate.getDate()).padStart(2, "0")}`;

//           return {
//             id: idCounter++,
//             flag: "NS",
//             "Sr No": rowIndex + 1, // Row number ("Sr No") starts from 1
//             date: formattedDate,

//             expenses: key,
//             amount: randomAmounts[rowIndex], // Assign generated amount from the randomAmounts array
//             remark: remark || "",
//           };
//         }).filter(Boolean);

//         const validatedEntries = entries.map((entry, index) => {
//           let amount = randomAmounts[index];

//           // Check if the amount is negative or outside the range
//           if (amount < minimumAmount) {
//             amount = minimumAmount; // Ensure amount is not below minimum
//           } else if (amount > maximumAmount) {
//             amount = maximumAmount; // Ensure amount does not exceed maximum
//           }

//           // Return the updated entry with validated amount
//           return {
//             ...entry,
//             amount, // Set validated amount
//           };
//         });

//         validatedEntries.sort((a, b) => new Date(a.date) - new Date(b.date));

//         validatedEntries.forEach((entry, index) => {
//           entry["Sr No"] = index + 1;
//         });

//         // Calculate the total amount of generated entries
//         const totalGeneratedAmount = validatedEntries.reduce((sum, entry) => sum + entry.amount, 0);

//         // Calculate the difference between totalAmount and totalGeneratedAmount
//         const amountDifference = totalAmount - totalGeneratedAmount;

//         // Adjust the last entry to ensure totalAmount is met
//         if (validatedEntries.length > 0 && amountDifference !== 0) {
//           // Adjust the last entry's amount to match totalAmount, but ensure it's within max amount
//           validatedEntries[validatedEntries.length - 1].amount += amountDifference;

//           // Ensure the adjusted last amount is not greater than maximumAmount
//           validatedEntries[validatedEntries.length - 1].amount = Math.min(validatedEntries[validatedEntries.length - 1].amount, maximumAmount);

//           // Ensure that the last amount is not negative
//           validatedEntries[validatedEntries.length - 1].amount = Math.max(validatedEntries[validatedEntries.length - 1].amount, minimumAmount);
//         }

//         // Add entries for this row to the allEntries array
//         allEntries.push(...validatedEntries);
//       }

//       if (fixAmount === "" && fixDate === "") {
//         // Ensure fixDate is a valid day of the month
//         const startMonth = 3; // Start from the 3rd month (0-indexed: March)
//         const startYear = financialStart.getFullYear();

//         // Generate totalEntries number of rows with random months
//         const entries = Array.from({ length: totalEntries }, (_, rowIndex) => {
//           // Select a random month within the financial year
//           const randomDay = Math.floor(Math.random() * 31) + 1;
//           const randomMonthOffset = Math.floor(Math.random() * 12); // Random month offset between 0 and 11
//           const year = startMonth + rowIndex < 12 ? startYear : startYear + 1;
//           const month = (startMonth + randomMonthOffset) % 12;

//           // Get the last day of the current month
//           const lastDayOfMonth = getLastDayOfMonth(year, month);

//           // Use the lesser of randomDay or lastDayOfMonth to prevent invalid dates
//           const adjustedDay = Math.min(randomDay, lastDayOfMonth);

//           // Create the entry date with the fixed day but random month
//           const entryDate = new Date(year, month, adjustedDay);

//           // Ensure the generated date is within the financial year
//           if (entryDate < financialStart || entryDate > financialEnd) return null;

//           // Divide totalAmount into parts, ensuring each part is within the min and max limits
//           const randomAmounts = [];
//           let remainingAmount = totalAmount;
//           const parts = totalEntries;

//           // Generate random parts for each row ensuring it's within min and max limits
//           for (let i = 0; i < parts - 1; i++) {
//             let randomPart = Math.floor(Math.random() * (maximumAmount - minimumAmount + 1)) + minimumAmount;
//             randomAmounts.push(randomPart);
//             remainingAmount -= randomPart; // Deduct the generated part from remainingAmount

//             // Ensure the remaining amount doesn't go below 0
//             if (remainingAmount < 0) {
//               remainingAmount = 0;
//             }
//           }

//           // The last part will be the remaining amount, but capped at maximumAmount
//           let lastPart = Math.min(remainingAmount, maximumAmount);
//           randomAmounts.push(lastPart);

//           // Ensure that no negative amounts are generated in the random amounts
//           randomAmounts.forEach((amount, index) => {
//             randomAmounts[index] = Math.max(amount, minimumAmount); // Prevent negative or too low amounts
//           });

//           // Format date to YYYY-MM-DD without timezone issues
//           const formattedDate = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, "0")}-${String(entryDate.getDate()).padStart(2, "0")}`;

//           return {
//             id: idCounter++,
//             flag: "NS",
//             "Sr No": rowIndex + 1, // Row number ("Sr No") starts from 1
//             date: formattedDate,
//             expenses: key,
//             amount: randomAmounts[rowIndex], // Assign generated amount from the randomAmounts array
//             remark: remark || "",
//           };
//         }).filter(Boolean); // Filter out null entries

//         entries.sort((a, b) => new Date(a.date) - new Date(b.date));

//         entries.forEach((entry, index) => {
//           entry["Sr No"] = index + 1;
//         });

//         // Calculate the total amount of generated entries
//         const totalGeneratedAmount = entries.reduce((sum, entry) => sum + entry.amount, 0);

//         // Calculate the difference between totalAmount and totalGeneratedAmount
//         const amountDifference = totalAmount - totalGeneratedAmount;

//         // Adjust the last entry to ensure totalAmount is met
//         if (entries.length > 0 && amountDifference !== 0) {
//           // Adjust the last entry's amount to match totalAmount, but ensure it's within max amount
//           entries[entries.length - 1].amount += amountDifference;

//           // Ensure the adjusted last amount is not greater than maximumAmount
//           entries[entries.length - 1].amount = Math.min(entries[entries.length - 1].amount, maximumAmount);

//           // Ensure that the last amount is not negative and meets the minimumAmount constraint
//           entries[entries.length - 1].amount = Math.max(entries[entries.length - 1].amount, minimumAmount);
//         }

//         // Add entries for this row to the allEntries array
//         allEntries.push(...entries);
//       }
//     });
//     // console.log("allEntries", allEntries);
//     return allEntries; // Return the combined array of all entries
//   };

//   const handleInputChange = (key, field, value) => {
//     setEditableTableData((prevData) => {
//       return prevData.map((item) => {
//         if (item.key === key) {
//           let updatedItem = { ...item, [field]: value };

//           // Calculate totalEntries, totalAmount, minimumAmount, and maximumAmount
//           if (field === "fixAmount" || field === "fixDate") {
//             const fixAmount = updatedItem.fixAmount ? parseFloat(updatedItem.fixAmount) : 0;
//             const fixDate = updatedItem.fixDate ? parseInt(updatedItem.fixDate) : 0;

//             if (fixAmount > 0 && fixDate > 0) {
//               updatedItem.totalEntries = 12; // Set total entries to 12 months
//               updatedItem.totalAmount = Math.floor(fixAmount * 12); // Calculate total amount (fixAmount * 12) and round down to the nearest integer
//               updatedItem.minimumAmount = Math.floor(fixAmount); // Set minimum amount to fixAmount and round down
//               updatedItem.maximumAmount = Math.floor(fixAmount); // Set maximum amount to fixAmount and round down
//             }
//           }

//           // New logic for calculating Minimum and Maximum Amount when Total Entries and Total Amount are set
//           if (field === "totalEntries" || field === "totalAmount") {
//             const totalAmount = updatedItem.totalAmount ? parseFloat(updatedItem.totalAmount) : 0;
//             const totalEntries = updatedItem.totalEntries ? parseInt(updatedItem.totalEntries) : 0;

//             if (totalAmount > 0 && totalEntries > 0) {
//               const amountPerEntry = totalAmount / totalEntries;

//               // Calculate minimum and maximum amounts and round them to the nearest integer
//               updatedItem.minimumAmount = Math.floor(amountPerEntry - amountPerEntry * 0.5); // Deduct 50% from the amount per entry and round down
//               updatedItem.maximumAmount = Math.floor(amountPerEntry + amountPerEntry * 0.5); // Add 50% to the amount per entry and round down
//             }
//           }

//           return updatedItem;
//         }
//         return item;
//       });
//     });
//   };

//   const handleKeyDown = (e, rowKey, columnKey) => {
//     const columnKeys = columnsStep2.map((col) => col.key); // List of column keys
//     const currentIndex = columnKeys.indexOf(columnKey);

//     let nextInput;

//     if (e.key === "Enter" || e.key === "ArrowRight") {
//       // Move to the next column
//       if (currentIndex < columnKeys.length - 1) {
//         const nextColumnKey = columnKeys[currentIndex + 1];
//         nextInput = document.querySelector(`[data-row-key="${rowKey}"][data-column-key="${nextColumnKey}"]`);
//       }
//     } else if (e.key === "ArrowLeft") {
//       // Move to the previous column
//       if (currentIndex > 0) {
//         const prevColumnKey = columnKeys[currentIndex - 1];
//         nextInput = document.querySelector(`[data-row-key="${rowKey}"][data-column-key="${prevColumnKey}"]`);
//       }
//     } else if (e.key === "ArrowDown") {
//       // Move to the same column in the next row
//       const nextRowKey = parseInt(rowKey, 10) + 1; // Assuming rowKey is numeric
//       nextInput = document.querySelector(`[data-row-key="${nextRowKey}"][data-column-key="${columnKey}"]`);
//     } else if (e.key === "ArrowUp") {
//       // Move to the same column in the previous row
//       const prevRowKey = parseInt(rowKey, 10) - 1; // Assuming rowKey is numeric
//       nextInput = document.querySelector(`[data-row-key="${prevRowKey}"][data-column-key="${columnKey}"]`);
//     }

//     // If a valid nextInput is found, focus it
//     if (nextInput) {
//       e.preventDefault();
//       nextInput.focus();
//     }
//   };

//   const deleteLedger = (e) => {
//     setEditableTableData(editableTableData.filter((row) => row.key !== e.key));
//     setLedgerList(ledgerList.map((row) => (row.key === e.key ? { ...row, status: "" } : row)));
//     setSelectedLedgers(selectedLedgers.filter((row) => row !== e.key));

//     if (selectedLedgers.length === 1) {
//       setCurrent((prev) => prev - 1);
//     }
//   };

//   const columnsStep2 = [
//     {
//       title: "Expenses Name",
//       dataIndex: "selectedLedger",
//       key: "selectedLedger",
//       width: 150,
//       render: (text) => <span>{text}</span>,
//     },
//     {
//       title: "From Date",
//       dataIndex: "fromDate",
//       key: "fromDate",
//       render: (_, record) => <Input data-row-key={record.key} data-column-key="fromDate" value={record.fromDate} onChange={(e) => handleInputChange(record.key, "fromDate", e.target.value)} onKeyDown={(e) => handleKeyDown(e, record.key, "fromDate")} />,
//     },
//     {
//       title: "To Date",
//       dataIndex: "toDate",
//       key: "toDate",
//       render: (_, record) => <Input data-row-key={record.key} data-column-key="toDate" value={record.toDate} onChange={(e) => handleInputChange(record.key, "toDate", e.target.value)} onKeyDown={(e) => handleKeyDown(e, record.key, "toDate")} />,
//     },
//     {
//       title: "Fix Amount",
//       dataIndex: "fixAmount",
//       key: "fixAmount",
//       render: (_, record) => (
//         <Input data-row-key={record.key} data-column-key="fixAmount" value={record.fixAmount} onKeyDown={(e) => handleKeyDown(e, record.key, "fixAmount")} onChange={(e) => handleInputChange(record.key, "fixAmount", e.target.value.replace(/[^0-9]/g, ""))} style={{ textAlign: "right" }} placeholder="0" />
//       ),
//     },
//     {
//       title: "Fix Date",
//       dataIndex: "fixDate",
//       key: "fixDate",
//       // width: 55,
//       render: (_, record) => (
//         <Input
//           data-row-key={record.key}
//           data-column-key="fixDate"
//           value={record.fixDate}
//           onKeyDown={(e) => handleKeyDown(e, record.key, "fixDate")}
//           onChange={(e) => {
//             const newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
//             if (newValue >= 1 && newValue <= 31) {
//               handleInputChange(record.key, "fixDate", newValue);
//             } else if (newValue === "") {
//               handleInputChange(record.key, "fixDate", newValue); // Allow clearing the value
//             }
//           }}
//           style={{ textAlign: "right" }}
//           placeholder="DD"
//         />
//       ),
//     },
//     {
//       title: "Total Entries",
//       dataIndex: "totalEntries",
//       key: "totalEntries",
//       render: (_, record) => (
//         <Input data-row-key={record.key} data-column-key="totalEntries" value={record.totalEntries} onKeyDown={(e) => handleKeyDown(e, record.key, "totalEntries")} onChange={(e) => handleInputChange(record.key, "totalEntries", e.target.value.replace(/[^0-9]/g, ""))} style={{ textAlign: "right" }} placeholder="0" />
//       ),
//     },
//     {
//       title: "Total Amount",
//       dataIndex: "totalAmount",
//       key: "totalAmount",
//       render: (_, record) => (
//         <Input data-row-key={record.key} data-column-key="totalAmount" value={record.totalAmount} onKeyDown={(e) => handleKeyDown(e, record.key, "totalAmount")} onChange={(e) => handleInputChange(record.key, "totalAmount", e.target.value.replace(/[^0-9]/g, ""))} style={{ textAlign: "right" }} placeholder="0" />
//       ),
//     },
//     {
//       title: "Minimum Amount",
//       dataIndex: "minimumAmount",
//       key: "minimumAmount",
//       render: (_, record) => (
//         <Input data-row-key={record.key} data-column-key="minimumAmount" value={record.minimumAmount} onKeyDown={(e) => handleKeyDown(e, record.key, "minimumAmount")} onChange={(e) => handleInputChange(record.key, "minimumAmount", e.target.value.replace(/[^0-9]/g, ""))} style={{ textAlign: "right" }} placeholder="0" />
//       ),
//     },
//     {
//       title: "Maximum Amount",
//       dataIndex: "maximumAmount",
//       key: "maximumAmount",
//       render: (_, record) => (
//         <Input data-row-key={record.key} data-column-key="maximumAmount" value={record.maximumAmount} onKeyDown={(e) => handleKeyDown(e, record.key, "maximumAmount")} onChange={(e) => handleInputChange(record.key, "maximumAmount", e.target.value.replace(/[^0-9]/g, ""))} style={{ textAlign: "right" }} placeholder="0" />
//       ),
//     },
//     {
//       title: "Remark",
//       dataIndex: "remark",
//       key: "remark",
//       render: (_, record) => <Input data-row-key={record.key} data-column-key="remark" value={record.remark} onKeyDown={(e) => handleKeyDown(e, record.key, "remark")} onChange={(e) => handleInputChange(record.key, "remark", e.target.value)} />,
//     },
//     {
//       title: "Delete",
//       width: 55,
//       render: (text) => (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <Button style={{ cursor: "pointer", color: "red", border: "none", background: "transparent", outline: "none" }} onClick={() => deleteLedger(text)} icon={<DeleteOutlined />} />
//         </div>
//       ),
//     },
//   ];

//   const columnsStep1 = [
//     {
//       title: "Ledger",
//       dataIndex: "ledger",
//       key: "ledger",
//       render: (text) => (
//         <span style={{ cursor: "pointer", color: "#0bb197" }} onClick={() => handleLedgerClick(text)}>
//           {text}
//         </span>
//       ),
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//     },
//   ];

//   const columnsStep3 = [
//     {
//       title: "Index",
//       render: (text, record, index) => index + 1, // Adds 1 to make the index start from 1
//       key: "index",
//     },
//     {
//       title: "Sr No",
//       dataIndex: "Sr No",
//       key: "Sr No",
//     },
//     {
//       title: "Date",
//       dataIndex: "date",
//       key: "date",
//     },
//     {
//       title: "Expenses",
//       dataIndex: "expenses",
//       key: "expenses",
//     },
//     {
//       title: "Amount",
//       dataIndex: "amount",
//       key: "amount",
//     },
//     {
//       title: "Remark",
//       dataIndex: "remark",
//       key: "remark",
//     },
//   ];
//   //   console.log("ledgerList", ledgerList);
//   const renderStepContent = () => {
//     if (current === 0) {
//       return (
//         <>
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               height: "65px",
//             }}
//           >
//             <Radio.Group onChange={handlePaymentTypeChange} value={paymentType}>
//               <Radio value="receipt">Receipt</Radio>
//               <Radio value="payment">Payment</Radio>
//             </Radio.Group>
//           </div>
//           <div className="auto_expence_party_name_div">
//             <label htmlFor="partyName" className="auto_expence_party_name">
//               Party Name<sup className="text-danger">*</sup>
//             </label>
//             <Select showSearch className="auto_expence_party_name_input_eliment_Select" name="partyName" value={partyName} dropdownStyle={{ zIndex: 9999 }} onChange={(value) => setPartyName(value)}>
//               {ledgerForPartyName.map((item, idx) => (
//                 <Select.Option key={idx} value={item}>
//                   {item}
//                 </Select.Option>
//               ))}
//             </Select>
//           </div>
//         </>
//       );
//     }

//     if (current === 1) {
//       return (
//         <div style={{ height: "335px", width: "500px", overflowY: "auto" }}>
//           <Table dataSource={ledgerList} columns={columnsStep1} pagination={false} scroll={{ y: 300 }} className="custom-table" />
//         </div>
//       );
//     }
//     if (current === 2) {
//       return (
//         <div style={{ height: "335px", width: "1200px", overflowY: "auto" }}>
//           <Table dataSource={editableTableData} columns={columnsStep2} pagination={false} scroll={{ y: 300 }} className="custom-table" />
//         </div>
//       );
//     }
//     if (current === 3) {
//       return (
//         <div style={{ padding: "20px", width: "1200px" }}>
//           <Table
//             columns={columnsStep3}
//             dataSource={monthlyEntries}
//             pagination={false}
//             rowKey="Sr No"
//             scroll={{ x: true }} // Makes the table horizontally scrollable if necessary
//             className="custom-table"
//           />
//         </div>
//       );
//     }

//     return null;
//   };

//   return (
//     <Modal
//       centered
//       width="fit-content"
//       open={modal2Open}
//       onOk={handleOkClick}
//       onCancel={handleCancel}
//       className="autoExpensesForm-modal"
//       footer={
//         <>
//           {current === 0 && (
//             <>
//               <Button key="cancel" onClick={handleCancel} style={{ width: 100 }}>
//                 Cancel
//               </Button>
//               <Button key="next" type="primary" style={{ width: 100 }} onClick={handleNext}>
//                 Next Step
//               </Button>
//             </>
//           )}
//           {current === 1 && (
//             <>
//               <Button key="previous" onClick={handlePrevious} style={{ width: 100 }}>
//                 Previous
//               </Button>
//               <Button key="next" type="primary" style={{ width: 100 }} onClick={performOnSelectLedger}>
//                 Next Step
//               </Button>
//             </>
//           )}
//           {current === 2 && (
//             <>
//               <Button key="previous" onClick={handlePrevious} style={{ width: 100 }}>
//                 Previous
//               </Button>
//               <Button key="finish" type="primary" onClick={handleSubmit} style={{ width: 100 }}>
//                 Create Data
//               </Button>
//             </>
//           )}
//           {current === 3 && (
//             <>
//               <Button key="previous" onClick={handlePrevious} style={{ width: 100 }}>
//                 Previous
//               </Button>
//               <Button key="finish" type="primary" onClick={handleSendData} style={{ width: 100 }}>
//                 Finish
//               </Button>
//             </>
//           )}
//         </>
//       }
//     >
//       <div>{renderStepContent()}</div>
//     </Modal>
//   );
// };

// export default withProfile(AutoExpensesForm);

import React, { useState, useEffect } from "react";
import { Button, Modal, Radio, Table, Input, Select, ConfigProvider, Checkbox } from "antd";
import { withProfile } from "../../../../contexts/profileContext";
import axios from "axios";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const AutoExpensesForm = ({ name, modal2Open, handleOkClick, handleCancel, mainTableData, validatedProfile, fetchData }) => {
    const [current, setCurrent] = useState(0);
    const [ledgerList, setLedgerList] = useState([]);
    const [ledgerForPartyName, setLedgerForPartyName] = useState([]);
    const [selectedLedgers, setSelectedLedgers] = useState([]);

    const [paymentType, setPaymentType] = useState("receipt");
    const [partyName, setPartyName] = useState("");
    const [editableTableData, setEditableTableData] = useState([]);

    useEffect(() => {
        setEditableTableData([]);
        if (mainTableData) {
            const uniqueLedgers = [...new Set(mainTableData.ledgermaster.map((row) => row.name))];
            setLedgerForPartyName(uniqueLedgers.sort());
            setLedgerList(uniqueLedgers.map((ledger) => ({ key: ledger, ledger, status: "" })).sort());
        }
    }, [mainTableData]);

    const handlePaymentTypeChange = (e) => {
        setPaymentType(e.target.value);
    };

    const handleLedgerClick = (ledgerName) => {
        setLedgerList((prevList) =>
            prevList.map(
                (item) => (item.ledger === ledgerName ? { ...item, status: !item.status } : item) // Toggle the boolean status
            )
        );

        setSelectedLedgers(
            (prevSelected) =>
                prevSelected.includes(ledgerName)
                    ? prevSelected.filter((name) => name !== ledgerName) // Remove if already selected
                    : [...prevSelected, ledgerName] // Add if not selected
        );
    };

    const getCurrentFinancialYearDates = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth(); // 0 = January, 11 = December

        let startYear, endYear;

        // Determine the start and end years based on the financial year logic
        if (currentMonth < 3) {
            // Jan, Feb, Mar belong to the previous financial year
            startYear = currentYear - 1;
            endYear = currentYear;
        } else {
            // Apr-Dec belong to the current financial year
            startYear = currentYear;
            endYear = currentYear + 1;
        }

        // Construct the first and last dates
        const firstDate = `01/04/${startYear}`;
        const lastDate = `31/03/${endYear}`;

        return { firstDate, lastDate };
    };

    const { firstDate, lastDate } = getCurrentFinancialYearDates();
    const performOnSelectLedger = () => {
        // Prepare data for the second table
        if (selectedLedgers.length > 0) {
            const newEditableData = selectedLedgers.map((ledger) => ({
                key: ledger,
                selectedLedger: ledger,
                fromDate: firstDate,
                toDate: lastDate,
                fixAmount: "",
                fixDate: "",
                totalEntries: "",
                totalAmount: "",
                minimumAmount: "",
                maximumAmount: "",
                remark: "",
            }));
            setEditableTableData(newEditableData);
            setCurrent((prev) => prev + 1);
        } else {
            alert("Select expense");
        }
    };
    const handlePrevious = () => {
        setCurrent((prev) => prev - 1);
    };
    const handleNext = () => {
        setCurrent((prev) => prev + 1);
    };
    const [monthlyEntries, setMonthlyEntries] = useState([]);

    const handleSubmit = () => {
        setMonthlyEntries(generateMonthlyEntries(editableTableData, firstDate, lastDate));
        setCurrent((prev) => prev + 1);
    };

    const handleSendData = async () => {
        const randomInt = Math.floor(Math.random() * 900) + 100;

        const data = {
            autoexpensesdata: { expenses_name: monthlyEntries },
            autoexpenses: { name: `Autoexpenses ${randomInt}`, total_items: monthlyEntries.length },
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            vtype: paymentType,
            partyname: partyName,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/autoExpensesUpdate`, data, {
                headers: { "Content-Type": "application/json" },
            });
            fetchData();
            handleOkClick(); // Close the modal
            console.log("response", response);
        } catch (error) {
            console.error("Submission error:", error);
        }
    };

    const generateMonthlyEntries = (editableTableData, firstDate, lastDate) => {
        // Validate the financial year dates
        if (!firstDate || !lastDate) {
            console.error("Invalid financial year dates.");
            return [];
        }

        const financialStart = new Date(firstDate);
        const financialEnd = new Date(lastDate);

        // Helper to get the last day of a month
        const getLastDayOfMonth = (year, month) => new Date(year, month + 1, 0).getDate();

        const allEntries = []; // Array to store all the generated entries
        let idCounter = 1;
        console.log("editableTableData", editableTableData);
        editableTableData.forEach((item, rowIndex) => {
            const { fromDate, toDate, fixDate, fixAmount, remark, key, totalAmount, totalEntries, minimumAmount, maximumAmount } = item;

            if (fixAmount > 0) {
                // Ensure fixDate is a valid day of the month
                const fixDay = parseInt(fixDate, 10); // Convert to a number
                if (isNaN(fixDay) || fixDay < 1 || fixDay > 31) {
                    console.log(`Invalid fixDate '${fixDate}' for row ${rowIndex + 1}.`);
                    return;
                }

                const startMonth = 3; // Start from the 3rd month (0-indexed: March)
                const startYear = financialStart.getFullYear();

                // Generate 12 months of entries starting from the 3rd month
                const entries = Array.from({ length: 12 }, (_, monthOffset) => {
                    const year = startMonth + monthOffset < 12 ? startYear : startYear + 1; // Handle year rollover
                    const month = (startMonth + monthOffset) % 12; // Keep months within 0-11 range

                    // Get the last day of the current month
                    const lastDayOfMonth = getLastDayOfMonth(year, month);

                    // Use the lesser of fixDay or lastDayOfMonth to prevent invalid dates
                    const adjustedDay = Math.min(fixDay, lastDayOfMonth);

                    // Create the entry date
                    const entryDate = new Date(year, month, adjustedDay);

                    // Ensure the generated date is within the financial year
                    if (entryDate < financialStart || entryDate > financialEnd) return null;

                    // Format date to YYYY-MM-DD without timezone issues
                    const formattedDate = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, "0")}-${String(
                        entryDate.getDate()
                    ).padStart(2, "0")}`;

                    return {
                        id: idCounter++,
                        flag: "NS",
                        "Sr No": monthOffset + 1, // Reset the "Sr No" for each row
                        date: formattedDate,

                        expenses: key,
                        amount: parseFloat(fixAmount) || 0,
                        remark: remark || "",
                    };
                }).filter(Boolean); // Filter out null entries

                allEntries.push(...entries); // Add entries for this row to the allEntries array
            }

            if (fixAmount === "" && fixDate) {
                // Ensure fixDate is a valid day of the month
                const fixDay = parseInt(fixDate, 10); // Convert to a number
                if (isNaN(fixDay) || fixDay < 1 || fixDay > 31) {
                    console.log(`Invalid fixDate '${fixDate}' for row ${rowIndex + 1}.`);
                    return;
                }

                const startMonth = 3; // Start from the 3rd month (0-indexed: March)
                const startYear = financialStart.getFullYear();

                const randomAmounts = [];
                // Generate totalEntries number of rows with random months
                const entries = Array.from({ length: totalEntries }, (_, rowIndex) => {
                    // Select a random month within the financial year
                    const randomMonthOffset = Math.floor(Math.random() * 12); // Random month offset between 0 and 11
                    const year = startMonth + rowIndex < 12 ? startYear : startYear + 1;
                    const month = (startMonth + rowIndex) % 12;

                    // Get the last day of the current month
                    const lastDayOfMonth = getLastDayOfMonth(year, month);

                    // Use the lesser of fixDay or lastDayOfMonth to prevent invalid dates
                    const adjustedDay = Math.min(fixDay, lastDayOfMonth);

                    // Create the entry date with the fixed day but random month
                    const entryDate = new Date(year, month, adjustedDay);

                    // Ensure the generated date is within the financial year
                    if (entryDate < financialStart || entryDate > financialEnd) return null;

                    // Divide totalAmount into parts, ensuring each part is within the min and max limits
                    let remainingAmount = totalAmount;
                    const parts = totalEntries;

                    // Generate random parts for each row ensuring it's within min and max limits
                    for (let i = 0; i < parts - 1; i++) {
                        let randomPart = Math.floor(Math.random() * (maximumAmount - minimumAmount + 1)) + minimumAmount;

                        // Ensure random part is positive and valid
                        randomPart = Math.max(randomPart, minimumAmount);
                        randomAmounts.push(randomPart);
                        remainingAmount -= randomPart; // Deduct the generated part from remainingAmount

                        // Ensure the remaining amount doesn't go below 0
                        if (remainingAmount < 0) {
                            remainingAmount = 0;
                        }
                    }

                    // The last part will be the remaining amount, but capped at maximumAmount
                    let lastPart = Math.min(remainingAmount, maximumAmount);

                    // Ensure last part is not negative and is within min and max range
                    lastPart = Math.max(lastPart, minimumAmount);
                    randomAmounts.push(lastPart);

                    // Ensure that no negative amounts are generated in the random amounts
                    randomAmounts.forEach((amount, index) => {
                        randomAmounts[index] = Math.max(amount, minimumAmount); // Prevent negative or too low amounts
                    });

                    // Format date to YYYY-MM-DD without timezone issues
                    const formattedDate = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, "0")}-${String(
                        entryDate.getDate()
                    ).padStart(2, "0")}`;

                    return {
                        id: idCounter++,
                        flag: "NS",
                        "Sr No": rowIndex + 1, // Row number ("Sr No") starts from 1
                        date: formattedDate,

                        expenses: key,
                        amount: randomAmounts[rowIndex], // Assign generated amount from the randomAmounts array
                        remark: remark || "",
                    };
                }).filter(Boolean);

                const validatedEntries = entries.map((entry, index) => {
                    let amount = randomAmounts[index];

                    // Check if the amount is negative or outside the range
                    if (amount < minimumAmount) {
                        amount = minimumAmount; // Ensure amount is not below minimum
                    } else if (amount > maximumAmount) {
                        amount = maximumAmount; // Ensure amount does not exceed maximum
                    }

                    // Return the updated entry with validated amount
                    return {
                        ...entry,
                        amount, // Set validated amount
                    };
                });

                validatedEntries.sort((a, b) => new Date(a.date) - new Date(b.date));

                validatedEntries.forEach((entry, index) => {
                    entry["Sr No"] = index + 1;
                });

                // Calculate the total amount of generated entries
                const totalGeneratedAmount = validatedEntries.reduce((sum, entry) => sum + entry.amount, 0);

                // Calculate the difference between totalAmount and totalGeneratedAmount
                const amountDifference = totalAmount - totalGeneratedAmount;

                // Adjust the last entry to ensure totalAmount is met
                if (validatedEntries.length > 0 && amountDifference !== 0) {
                    // Adjust the last entry's amount to match totalAmount, but ensure it's within max amount
                    validatedEntries[validatedEntries.length - 1].amount += amountDifference;

                    // Ensure the adjusted last amount is not greater than maximumAmount
                    validatedEntries[validatedEntries.length - 1].amount = Math.min(
                        validatedEntries[validatedEntries.length - 1].amount,
                        maximumAmount
                    );

                    // Ensure that the last amount is not negative
                    validatedEntries[validatedEntries.length - 1].amount = Math.max(
                        validatedEntries[validatedEntries.length - 1].amount,
                        minimumAmount
                    );
                }

                // Add entries for this row to the allEntries array
                allEntries.push(...validatedEntries);
            }

            if (fixAmount === "" && fixDate === "") {
                // Ensure fixDate is a valid day of the month
                const startMonth = 3; // Start from the 3rd month (0-indexed: March)
                const startYear = financialStart.getFullYear();

                // Generate totalEntries number of rows with random months
                const entries = Array.from({ length: totalEntries }, (_, rowIndex) => {
                    // Select a random month within the financial year
                    const randomDay = Math.floor(Math.random() * 31) + 1;
                    const randomMonthOffset = Math.floor(Math.random() * 12); // Random month offset between 0 and 11
                    const year = startMonth + rowIndex < 12 ? startYear : startYear + 1;
                    const month = (startMonth + randomMonthOffset) % 12;

                    // Get the last day of the current month
                    const lastDayOfMonth = getLastDayOfMonth(year, month);

                    // Use the lesser of randomDay or lastDayOfMonth to prevent invalid dates
                    const adjustedDay = Math.min(randomDay, lastDayOfMonth);

                    // Create the entry date with the fixed day but random month
                    const entryDate = new Date(year, month, adjustedDay);

                    // Ensure the generated date is within the financial year
                    if (entryDate < financialStart || entryDate > financialEnd) return null;

                    // Divide totalAmount into parts, ensuring each part is within the min and max limits
                    const randomAmounts = [];
                    let remainingAmount = totalAmount;
                    const parts = totalEntries;

                    // Generate random parts for each row ensuring it's within min and max limits
                    for (let i = 0; i < parts - 1; i++) {
                        let randomPart = Math.floor(Math.random() * (maximumAmount - minimumAmount + 1)) + minimumAmount;
                        randomAmounts.push(randomPart);
                        remainingAmount -= randomPart; // Deduct the generated part from remainingAmount

                        // Ensure the remaining amount doesn't go below 0
                        if (remainingAmount < 0) {
                            remainingAmount = 0;
                        }
                    }

                    // The last part will be the remaining amount, but capped at maximumAmount
                    let lastPart = Math.min(remainingAmount, maximumAmount);
                    randomAmounts.push(lastPart);

                    // Ensure that no negative amounts are generated in the random amounts
                    randomAmounts.forEach((amount, index) => {
                        randomAmounts[index] = Math.max(amount, minimumAmount); // Prevent negative or too low amounts
                    });

                    // Format date to YYYY-MM-DD without timezone issues
                    const formattedDate = `${entryDate.getFullYear()}-${String(entryDate.getMonth() + 1).padStart(2, "0")}-${String(
                        entryDate.getDate()
                    ).padStart(2, "0")}`;

                    return {
                        id: idCounter++,
                        flag: "NS",
                        "Sr No": rowIndex + 1, // Row number ("Sr No") starts from 1
                        date: formattedDate,
                        expenses: key,
                        amount: randomAmounts[rowIndex], // Assign generated amount from the randomAmounts array
                        remark: remark || "",
                    };
                }).filter(Boolean); // Filter out null entries

                entries.sort((a, b) => new Date(a.date) - new Date(b.date));

                entries.forEach((entry, index) => {
                    entry["Sr No"] = index + 1;
                });

                // Calculate the total amount of generated entries
                const totalGeneratedAmount = entries.reduce((sum, entry) => sum + entry.amount, 0);

                // Calculate the difference between totalAmount and totalGeneratedAmount
                const amountDifference = totalAmount - totalGeneratedAmount;

                // Adjust the last entry to ensure totalAmount is met
                if (entries.length > 0 && amountDifference !== 0) {
                    // Adjust the last entry's amount to match totalAmount, but ensure it's within max amount
                    entries[entries.length - 1].amount += amountDifference;

                    // Ensure the adjusted last amount is not greater than maximumAmount
                    entries[entries.length - 1].amount = Math.min(entries[entries.length - 1].amount, maximumAmount);

                    // Ensure that the last amount is not negative and meets the minimumAmount constraint
                    entries[entries.length - 1].amount = Math.max(entries[entries.length - 1].amount, minimumAmount);
                }

                // Add entries for this row to the allEntries array
                allEntries.push(...entries);
            }
        });
        // console.log("allEntries", allEntries);
        return allEntries; // Return the combined array of all entries
    };

    const handleInputChange = (key, field, value) => {
        setEditableTableData((prevData) => {
            return prevData.map((item) => {
                if (item.key === key) {
                    let updatedItem = { ...item, [field]: value };

                    // Calculate totalEntries, totalAmount, minimumAmount, and maximumAmount
                    if (field === "fixAmount" || field === "fixDate") {
                        const fixAmount = updatedItem.fixAmount ? parseFloat(updatedItem.fixAmount) : 0;
                        const fixDate = updatedItem.fixDate ? parseInt(updatedItem.fixDate) : 0;

                        if (fixAmount > 0 && fixDate > 0) {
                            updatedItem.totalEntries = 12; // Set total entries to 12 months
                            updatedItem.totalAmount = Math.floor(fixAmount * 12); // Calculate total amount (fixAmount * 12) and round down to the nearest integer
                            updatedItem.minimumAmount = Math.floor(fixAmount); // Set minimum amount to fixAmount and round down
                            updatedItem.maximumAmount = Math.floor(fixAmount); // Set maximum amount to fixAmount and round down
                        }
                    }

                    // New logic for calculating Minimum and Maximum Amount when Total Entries and Total Amount are set
                    if (field === "totalEntries" || field === "totalAmount") {
                        const totalAmount = updatedItem.totalAmount ? parseFloat(updatedItem.totalAmount) : 0;
                        const totalEntries = updatedItem.totalEntries ? parseInt(updatedItem.totalEntries) : 0;

                        if (totalAmount > 0 && totalEntries > 0) {
                            const amountPerEntry = totalAmount / totalEntries;

                            // Calculate minimum and maximum amounts and round them to the nearest integer
                            updatedItem.minimumAmount = Math.floor(amountPerEntry - amountPerEntry * 0.5); // Deduct 50% from the amount per entry and round down
                            updatedItem.maximumAmount = Math.floor(amountPerEntry + amountPerEntry * 0.5); // Add 50% to the amount per entry and round down
                        }
                    }

                    return updatedItem;
                }
                return item;
            });
        });
    };

    const handleKeyDown = (e, rowKey, columnKey) => {
        const columnKeys = columnsStep2.map((col) => col.key); // List of column keys
        const currentIndex = columnKeys.indexOf(columnKey);

        let nextInput;

        if (e.key === "Enter" || e.key === "ArrowRight") {
            // Move to the next column
            if (currentIndex < columnKeys.length - 1) {
                const nextColumnKey = columnKeys[currentIndex + 1];
                nextInput = document.querySelector(`[data-row-key="${rowKey}"][data-column-key="${nextColumnKey}"]`);
            }
        } else if (e.key === "ArrowLeft") {
            // Move to the previous column
            if (currentIndex > 0) {
                const prevColumnKey = columnKeys[currentIndex - 1];
                nextInput = document.querySelector(`[data-row-key="${rowKey}"][data-column-key="${prevColumnKey}"]`);
            }
        } else if (e.key === "ArrowDown") {
            // Move to the same column in the next row
            const nextRowKey = parseInt(rowKey, 10) + 1; // Assuming rowKey is numeric
            nextInput = document.querySelector(`[data-row-key="${nextRowKey}"][data-column-key="${columnKey}"]`);
        } else if (e.key === "ArrowUp") {
            // Move to the same column in the previous row
            const prevRowKey = parseInt(rowKey, 10) - 1; // Assuming rowKey is numeric
            nextInput = document.querySelector(`[data-row-key="${prevRowKey}"][data-column-key="${columnKey}"]`);
        }

        // If a valid nextInput is found, focus it
        if (nextInput) {
            e.preventDefault();
            nextInput.focus();
        }
    };

    const deleteLedger = (e) => {
        setEditableTableData(editableTableData.filter((row) => row.key !== e.key));
        setLedgerList(ledgerList.map((row) => (row.key === e.key ? { ...row, status: "" } : row)));
        setSelectedLedgers(selectedLedgers.filter((row) => row !== e.key));

        if (selectedLedgers.length === 1) {
            setCurrent((prev) => prev - 1);
        }
    };

    const columnsStep2 = [
        {
            title: "Expenses Name",
            dataIndex: "selectedLedger",
            key: "selectedLedger",
            width: 150,
            render: (text) => <span>{text}</span>,
        },
        {
            title: "From Date",
            dataIndex: "fromDate",
            key: "fromDate",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="fromDate"
                    value={record.fromDate}
                    onChange={(e) => handleInputChange(record.key, "fromDate", e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "fromDate")}
                />
            ),
        },
        {
            title: "To Date",
            dataIndex: "toDate",
            key: "toDate",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="toDate"
                    value={record.toDate}
                    onChange={(e) => handleInputChange(record.key, "toDate", e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "toDate")}
                />
            ),
        },
        {
            title: "Fix Amount",
            dataIndex: "fixAmount",
            key: "fixAmount",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="fixAmount"
                    value={record.fixAmount}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "fixAmount")}
                    onChange={(e) => handleInputChange(record.key, "fixAmount", e.target.value.replace(/[^0-9]/g, ""))}
                    style={{ textAlign: "right" }}
                    placeholder="0"
                />
            ),
        },
        {
            title: "Fix Date",
            dataIndex: "fixDate",
            key: "fixDate",
            // width: 55,
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="fixDate"
                    value={record.fixDate}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "fixDate")}
                    onChange={(e) => {
                        const newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                        if (newValue >= 1 && newValue <= 31) {
                            handleInputChange(record.key, "fixDate", newValue);
                        } else if (newValue === "") {
                            handleInputChange(record.key, "fixDate", newValue); // Allow clearing the value
                        }
                    }}
                    style={{ textAlign: "right" }}
                    placeholder="DD"
                />
            ),
        },
        {
            title: "Total Entries",
            dataIndex: "totalEntries",
            key: "totalEntries",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="totalEntries"
                    value={record.totalEntries}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "totalEntries")}
                    onChange={(e) => handleInputChange(record.key, "totalEntries", e.target.value.replace(/[^0-9]/g, ""))}
                    style={{ textAlign: "right" }}
                    placeholder="0"
                />
            ),
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="totalAmount"
                    value={record.totalAmount}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "totalAmount")}
                    onChange={(e) => handleInputChange(record.key, "totalAmount", e.target.value.replace(/[^0-9]/g, ""))}
                    style={{ textAlign: "right" }}
                    placeholder="0"
                />
            ),
        },
        {
            title: "Minimum Amount",
            dataIndex: "minimumAmount",
            key: "minimumAmount",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="minimumAmount"
                    value={record.minimumAmount}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "minimumAmount")}
                    onChange={(e) => handleInputChange(record.key, "minimumAmount", e.target.value.replace(/[^0-9]/g, ""))}
                    style={{ textAlign: "right" }}
                    placeholder="0"
                />
            ),
        },
        {
            title: "Maximum Amount",
            dataIndex: "maximumAmount",
            key: "maximumAmount",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="maximumAmount"
                    value={record.maximumAmount}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "maximumAmount")}
                    onChange={(e) => handleInputChange(record.key, "maximumAmount", e.target.value.replace(/[^0-9]/g, ""))}
                    style={{ textAlign: "right" }}
                    placeholder="0"
                />
            ),
        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
            render: (_, record) => (
                <Input
                    data-row-key={record.key}
                    data-column-key="remark"
                    value={record.remark}
                    onKeyDown={(e) => handleKeyDown(e, record.key, "remark")}
                    onChange={(e) => handleInputChange(record.key, "remark", e.target.value)}
                />
            ),
        },
        {
            title: "Delete",
            width: 55,
            render: (text) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        style={{ cursor: "pointer", color: "red", border: "none", background: "transparent", outline: "none" }}
                        onClick={() => deleteLedger(text)}
                        icon={<DeleteOutlined />}
                    />
                </div>
            ),
        },
    ];

    const columnsStep1 = [
        {
            title: "Ledger",
            dataIndex: "ledger",
            key: "ledger",
            render: (text) => (
                <span style={{ cursor: "pointer", color: "#0bb197" }} onClick={() => handleLedgerClick(text)}>
                    {text}
                </span>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <Checkbox
                    className="expense_select_box"
                    type="checkbox"
                    style={{ outline: "none" }}
                    checked={record.status} // Assuming `status` is a boolean
                    onChange={(e) => {
                        handleLedgerClick(record.ledger); // Trigger the handleLedgerClick function
                    }}
                />
            ),
        },
    ];

    const columnsStep3 = [
        {
            title: "Index",
            render: (text, record, index) => index + 1, // Adds 1 to make the index start from 1
            key: "index",
        },
        {
            title: "Sr No",
            dataIndex: "Sr No",
            key: "Sr No",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Expenses",
            dataIndex: "expenses",
            key: "expenses",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "remark",
        },
    ];
    //   console.log("ledgerList", ledgerList);
    const renderStepContent = () => {
        if (current === 0) {
            return (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "65px",
                        }}
                    >
                        <Radio.Group onChange={handlePaymentTypeChange} value={paymentType}>
                            <Radio value="receipt">Receipt</Radio>
                            <Radio value="payment">Payment</Radio>
                        </Radio.Group>
                    </div>
                    <div className="auto_expence_party_name_div">
                        <label htmlFor="partyName" className="auto_expence_party_name">
                            Party Name<sup className="text-danger">*</sup>
                        </label>
                        <Select
                            showSearch
                            className="auto_expence_party_name_input_eliment_Select"
                            name="partyName"
                            style={{ minWidth: "400px" }}
                            value={partyName}
                            dropdownStyle={{ zIndex: 9999 }}
                            onChange={(value) => setPartyName(value)}
                        >
                            {ledgerForPartyName.map((item, idx) => (
                                <Select.Option key={idx} value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </>
            );
        }

        if (current === 1) {
            return (
                <div style={{ height: "335px", width: "500px", overflowY: "auto" }}>
                    <Table dataSource={ledgerList} columns={columnsStep1} pagination={false} scroll={{ y: 300 }} className="custom-table" />
                </div>
            );
        }
        if (current === 2) {
            return (
                <div style={{ height: "335px", width: "1200px", overflowY: "auto" }}>
                    <Table
                        dataSource={editableTableData}
                        columns={columnsStep2}
                        pagination={false}
                        scroll={{ y: 300 }}
                        className="custom-table"
                    />
                </div>
            );
        }
        if (current === 3) {
            return (
                <div style={{ padding: "20px", width: "1200px" }}>
                    <Table
                        columns={columnsStep3}
                        dataSource={monthlyEntries}
                        pagination={false}
                        rowKey="Sr No"
                        scroll={{ x: true }} // Makes the table horizontally scrollable if necessary
                        className="custom-table"
                    />
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#0bb197",
                    },
                }}
            >
                <Modal
                    centered
                    width="fit-content"
                    style={{ minWidth: "500px" }}
                    open={modal2Open}
                    onOk={handleOkClick}
                    onCancel={handleCancel}
                    className="autoExpensesForm-modal"
                    footer={
                        <>
                            {current === 0 && (
                                <>
                                    <Button key="cancel" onClick={handleCancel} style={{ width: 100 }}>
                                        Cancel
                                    </Button>
                                    <Button key="next" type="primary" style={{ width: 100 }} onClick={handleNext}>
                                        Next Step
                                    </Button>
                                </>
                            )}
                            {current === 1 && (
                                <>
                                    <Button key="previous" onClick={handlePrevious} style={{ width: 100 }}>
                                        Previous
                                    </Button>
                                    <Button key="next" type="primary" style={{ width: 100 }} onClick={performOnSelectLedger}>
                                        Next Step
                                    </Button>
                                </>
                            )}
                            {current === 2 && (
                                <>
                                    <Button key="previous" onClick={handlePrevious} style={{ width: 100 }}>
                                        Previous
                                    </Button>
                                    <Button key="finish" type="primary" onClick={handleSubmit} style={{ width: 100 }}>
                                        Create Data
                                    </Button>
                                </>
                            )}
                            {current === 3 && (
                                <>
                                    <Button key="previous" onClick={handlePrevious} style={{ width: 100 }}>
                                        Previous
                                    </Button>
                                    <Button key="finish" type="primary" onClick={handleSendData} style={{ width: 100 }}>
                                        Finish
                                    </Button>
                                </>
                            )}
                        </>
                    }
                >
                    <div>{renderStepContent()}</div>
                </Modal>
            </ConfigProvider>
        </>
    );
};

export default withProfile(AutoExpensesForm);
