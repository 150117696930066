import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { withProfile } from "../../../../../contexts/profileContext";
import { Button, Select } from "antd";
import bcrypt from "bcryptjs";
import { ArrowLeftOutlined } from "@ant-design/icons";

const AddUser = ({ validatedProfile }) => {
    const navigate = useNavigate();
    const location = useLocation();
    let { editkey } = location.state || {};
    const [editUserData, setEditUserData] = useState(null);
    // console.log("editUserData", editUserData);
    const initialState = {
        firstname: "",
        lasttname: "",
        email: "",
        mobile: "",
        password: "",
        assignRole: "",
        sub_user_company: [],
    };

    const [formData, setFormData] = useState(initialState);

    useEffect(() => {
        if (editkey) {
            getuserdatafromkey();
        }
    }, [editkey]);

    useEffect(() => {
        if (editUserData) {
            setFormData({
                firstname: editUserData.user_name?.split(" ")[0] || "",
                lasttname: editUserData.user_name?.split(" ")[1] || "",
                email: editUserData.user_email || "",
                mobile: editUserData.user_mobile || "",
                password: "",
                assignRole: editUserData.role_srno || "",
                sub_user_company: editUserData.sub_user_company.map(Number) || [],
            });
        }
    }, [editUserData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRoleChange = (value) => {
        setFormData({ ...formData, assignRole: value });
    };

    const handleCompanyChange = (value) => {
        setFormData({ ...formData, sub_user_company: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const mergedName = `${formData.firstname} ${formData.lasttname}`;
        const mobileRegex = /^[6-9]\d{9}$/; // Indian mobile number validation
        if (!mobileRegex.test(formData.mobile)) {
            alert("Mobile number must be exactly 10 digits.");
            return;
        }
        const number = `${formData.mobile}`;

        let dataToSend = {
            ...formData,
            parentUserId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            name: mergedName,
            number: number,
            sub_user_company: formData.sub_user_company,
            user_type: "S",
        };

        delete dataToSend.firstname;
        delete dataToSend.lasttname;
        delete dataToSend.mobile;
        if (dataToSend.assignRole === "") {
            alert("Create Role First,");
            return;
        }
        if (!editkey) {
            dataToSend.password = await hashPassword(formData.password);
            await submitData(`${process.env.REACT_APP_API_URL}/register`, dataToSend);
        } else {
            dataToSend.userid = editkey;
            delete dataToSend.password;
            await submitData(`${process.env.REACT_APP_API_URL}/update-subuser-userlist`, dataToSend);
        }
    };

    const hashPassword = async (password) => {
        const fixedSalt = "$2b$10$yourfixedsaltforyourapplication";
        return await bcrypt.hash(password, fixedSalt);
    };

    const submitData = async (url, data) => {
        try {
            const response = await axios.post(url, data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log("response", response);
            if (response.status === 200) {
                alert("Registration successful!");
                navigate("/users");
            }
        } catch (error) {
            alert(error.response.data.message);
        }
    };

    const [roleNames, setRoleNames] = useState([]);
    const [companyNames, setCompanyNames] = useState([]);

    useEffect(() => {
        getRoledata();
        getCompanydata();
    }, []);

    const getRoledata = async () => {
        const id = { parentUserId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/getRole`, id);
            const roleData = response.data.userDetails.map((data) => ({
                label: data.role_name,
                value: data.srno,
            }));
            setRoleNames(roleData);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getuserdatafromkey = async () => {
        const id = { userid: editkey };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/userby-userid`, id);
            setEditUserData(response.data.userDetails);
        } catch (error) {
            console.log("error", error);
        }
    };

    const getCompanydata = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/company-data`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            });
            const companyNames = response.data.getCompanyDatas.map((data) => ({
                label: data.company_name,
                value: data.company_id,
            }));
            setCompanyNames(companyNames);
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <div className="page-content">
            <div className="addclient">
                <div className="addclient-form">
                    <div className="addclientHeader d-flex justify-content-between">
                        <p className="fw-bold">Register Sub User</p>
                        <Button type="dashed">
                            <Link className="backto-rolemanagement" to="/users">
                                <ArrowLeftOutlined />
                            </Link>
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="client-name form-flax">
                            <div className="frist-name common">
                                <label htmlFor="firstname">First name</label>
                                <input
                                    type="text"
                                    name="firstname"
                                    className="common-input"
                                    id="firstname"
                                    value={formData.firstname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="last-name common">
                                <label htmlFor="lasttname">Last name</label>
                                <input
                                    type="text"
                                    name="lasttname"
                                    className="common-input"
                                    id="lasttname"
                                    value={formData.lasttname}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="client-Emailmobile form-flax">
                            <div className="email common">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="common-input"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mobile common">
                                <label htmlFor="mobile">Mobile</label>
                                <input
                                    type="text"
                                    name="mobile"
                                    className="common-input"
                                    id="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        {!editkey && (
                            <div className="client-password form-flax">
                                <div className="password common">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="common-input"
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        )}
                        <div className="client-assignRoleb form-flax common">
                            <label htmlFor="assignRole">Assign Role</label>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select Role"
                                optionFilterProp="label"
                                value={formData.assignRole}
                                onChange={handleRoleChange}
                                options={roleNames}
                            />
                        </div>
                        <div className="client-assigncompony form-flax common">
                            <label htmlFor="sub_user_company">Assign Company</label>
                            <Select
                                showSearch
                                mode="multiple"
                                allowClear
                                placeholder="Please select Company"
                                value={formData.sub_user_company}
                                optionFilterProp="label"
                                onChange={handleCompanyChange}
                                options={companyNames}
                            />
                        </div>
                        <div className="form-flax justify-content-center">
                            <button type="submit" className="adduser-btn w-25 d-flax py-2">
                                {editkey ? "Update User" : "Add User"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withProfile(AddUser);
