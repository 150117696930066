// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { DeleteOutlined } from "@ant-design/icons";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// import { Button } from "antd";
// import { AgGridReact } from "ag-grid-react";
// import { withProfile } from "../../../../contexts/profileContext";
// import { withRole } from "../../../../contexts/roleContext";
// import withRouter from "../../../Common/withRouter";
// import Spinner from "../../../Common/Spinner/Spinner";

// const AutoExpensesMainTable = ({ validatedProfile, role, urlPathName, mainTableData, fetchData, isLoading, setIsLoading }) => {
//     const [selectedRowKeys, setSelectedRowKeys] = useState([]);
//     const [users, setUsers] = useState();
//     const [colDefs, setColDefs] = useState([]);

//     const navigate = useNavigate();
//     const { pathnameWithSlash: currentPath, pathWithoutSlash, pathOnlyName } = urlPathName;
//     // console.log("pathWithoutSlash", pathWithoutSlash);

//     useEffect(() => {
//         fetchTableData();
//     }, [mainTableData, validatedProfile?.companyId]);

//     const fetchTableData = async () => {
//         setIsLoading(true);
//         try {
//             if (mainTableData) {
//                 const processedData = processDatas(mainTableData);
//                 console.log("processedData", processedData);
//                 setColDefs(generateColumns(processedData));
//                 setUsers(processedData);
//                 setIsLoading(false);
//             } else {
//                 setIsLoading(false);
//             }
//         } catch (error) {
//             setIsLoading(false);
//             console.error("Error fetching table data:", error);
//         }
//     };

//     const processDatas = (data) => {
//         return data[pathWithoutSlash]?.map((obj) =>
//             Object.entries(obj).reduce((acc, [key, value]) => {
//                 acc[key.replace(/_/g, " ")] = value;
//                 return acc;
//             }, {})
//         );
//     };

//     const generateColumns = (data) => {
//         if (!data || !data.length) return [];

//         const requiredFields = ["name", "total items"]; // Only these fields will be included

//         const ationolumns = [
//             {
//                 headerName: "Action",
//                 field: "delete",
//                 suppressHeaderMenuButton: true,
//                 width: 80,
//                 cellStyle: { textAlign: "center" },
//                 cellRenderer: (params) => {
//                     const isDeletable = role["/" + currentPath] ? role && ["D", "F"].includes(role[currentPath]) : true;
//                     // const buttonClass = isDeletable && (params.data.status !== "Complete" || params.data.flag !== "SY") ? "text-danger maintable-deleterow Table-deleterow" : "text-secondary maintable-deleterow Table-deleterow";
//                     const buttonClass =
//                         isDeletable && pathWithoutSlash === "autoexpenses"
//                             ? params.data.flag !== "SY"
//                             : params.data.status !== "Complete"
//                             ? "text-danger maintable-deleterow Table-deleterow"
//                             : "text-secondary maintable-deleterow Table-deleterow";

//                     return (
//                         <Button
//                             className={buttonClass}
//                             type="danger"
//                             disabled={!isDeletable || params.data.status === "Complete" || params.data.flag === "SY"}
//                             onClick={(event) => {
//                                 event.stopPropagation();
//                                 if (isDeletable) deleteSingleRow(params.data);
//                             }}
//                         >
//                             <DeleteOutlined />
//                         </Button>
//                     );
//                 },
//             },
//         ];
//         const indexColumns = [
//             {
//                 headerName: "Sr No",
//                 field: "sr no",
//                 width: 100,
//                 cellClass: "justify-center-cell",
//                 headerClass: "justify-center-header",
//                 suppressHeaderMenuButton: true,
//                 headerCheckboxSelection: true,
//                 checkboxSelection: true,
//                 valueGetter: (params) => params.node.rowIndex + 1,
//             },
//         ];

//         const columns = requiredFields
//             .filter((field) => field !== "delete" && field !== "sr no") // Exclude fields handled in `indexColumns`
//             .map((field) => ({
//                 headerName: field.replace(/^\w/, (c) => c.toUpperCase()), // Capitalize first letter for column header
//                 field: field.replace(/_/g, " "), // Replace underscores with spaces
//                 filter: false,
//                 // flex: 1,
//                 flex: getFlexValue(field, pathWithoutSlash),
//             }));

//         const statusColumn = {
//             headerName: "Status",
//             field: "status",
//             width: 120,
//             cellRenderer: (params) => <span className={params.value}>{params.value}</span>,
//             cellStyle: { textAlign: "center" },
//         };

//         return [...indexColumns, ...columns, statusColumn, ...ationolumns];
//     };

//     const getFlexValue = (field, path) => {
//         const flexMap = {
//             autoexpenses: { srno: 0.3, name: 2, "total item": 0.8 },
//         };
//         return flexMap[path]?.[field.toLowerCase()] || 1;
//     };

//     const deleteSingleRow = async (rowData) => {
//         console.log("rowData", rowData);
//         let deleteFile = window.confirm(`Are you want to delete "${rowData.name}" file?`);
//         if (deleteFile) {
//             const deleteID = rowData.srno;
//             try {
//                 const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
//                     userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
//                     companyId: validatedProfile.companyId,
//                     tablename: "autoexpenses",
//                     deleteID: [deleteID],
//                 });
//                 if (status === 200) {
//                     fetchData();
//                     fetchTableData();
//                 }
//             } catch (error) {
//                 console.error("Error deleting row:", error);
//             }
//         }
//     };

//     const deleteSelectedRows = async () => {
//         let deleteFile = window.confirm(`Are you want to delete all selected file?`);
//         if (deleteFile) {
//             try {
//                 const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
//                     userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
//                     companyId: validatedProfile.companyId,
//                     tablename: pathOnlyName,
//                     deleteID: selectedRowKeys,
//                 });
//                 if (status === 200) {
//                     fetchData();
//                     fetchTableData();
//                 }
//             } catch (error) {
//                 console.error("Error deleting selected rows:", error);
//             }
//         }
//     };

//     const renderDeleteButton = () =>
//         selectedRowKeys.length >= 2 &&
//         role &&
//         ["D", "F"].includes(role[currentPath]) && (
//             <Button type="primary" className="deleteSelectedRow" onClick={deleteSelectedRows}>
//                 Delete Selected Row
//             </Button>
//         );

//     const onSelectionChanged = (params) => {
//         const selectedIds = params.api.getSelectedRows().map((row) => row.srno);
//         setSelectedRowKeys(selectedIds);
//     };

//     return (
//         <>
//             <Spinner isSpinner={isLoading} />
//             <div className="ag-theme-alpine ag-main-table">
//                 <AgGridReact
//                     className="main_table_ag"
//                     rowData={users}
//                     columnDefs={colDefs}
//                     pagination
//                     rowSelection="multiple"
//                     paginationPageSize={20}
//                     paginationPageSizeSelector={[10, 20, 50, 100]}
//                     gridOptions={generateColumns}
//                     onCellClicked={(params) => {
//                         if (params.colDef.field !== "delete") {
//                             navigate("/autoExpensesTransactionsTable", {
//                                 state: {
//                                     key: params.data.srno,
//                                     pathWithoutSlash,
//                                     currentPath,
//                                     pathOnlyName,
//                                 },
//                             });
//                         }
//                     }}
//                     onSelectionChanged={onSelectionChanged}
//                 />
//                 {renderDeleteButton()}
//             </div>
//         </>
//     );
// };

// export default withProfile(withRole(withRouter(AutoExpensesMainTable)));

import React from "react";
import TableAll from "../../../../Layout/MainTable/Table";

const Banking = () => {
    return (
        <>
            <div className="page-content">
                <TableAll />
            </div>
        </>
    );
};

export default Banking;
