import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DatePicker, Input, Modal, Select, Table } from "antd";
import { YoutubeOutlined, ArrowLeftOutlined, DoubleLeftOutlined, SnippetsOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { withProfile } from "../../contexts/profileContext";
import SpliteTable from "./SplitPopUpBox/SplitTable";
import TallySyncTable from "./TallySyncPopUpBox/TallySyncTable";
import confirm from "antd/es/modal/confirm";
import ErrorModel from "./ErrorModel/ErrorModel";
import Spinner from "../../components/Common/Spinner/Spinner";
import AgGridSubTable from "./AgGridTable";

import { withRole } from "../../contexts/roleContext";

const TransactionsTable = ({ validatedProfile, subTableData, mainTableData, fetchSubTableData, role }) => {
    const [upadateLedger, setUpadateLedger] = useState([]); // For updating selected rows

    const [rowData, setRowData] = useState([]);
    const [fullRowData, setFullRowData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // New state for spinner
    const [ledgeroption, setLedgeroption] = useState([]);
    const [purchaseLedgerUnderPurchaseAccounts, setPurchaseLedgerUnderPurchaseAccounts] = useState([]);
    const [purchasevoucherType, setPurchasevoucherType] = useState([]);
    const [ledgerUnderSalesAccounts, setLedgerUnderSalesAccounts] = useState([]);
    const [stockitemNameOptions, setStockitemNameOptions] = useState([]);
    const [partyAcNameOptions, setPartyAcNameOptions] = useState([]);
    const [splitModalOpen, setSplitModalOpen] = useState(false);
    const [selectedSlpitIdData, setSelectedSlpitIdData] = useState(null);
    const [tallySyncModalOpen, setTallySyncModalOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // To store selected row keys
    const [selectedTallySyncId, setSelectedTallySyncId] = useState([]); // for data of TallySync id
    const [debitRs, setDebitRs] = useState(0);
    const [creditRs, setCreditRs] = useState(0);
    const [stockitemNameError, setStockitemNameError] = useState(false);
    const [ledgerNameError, setLedgerNameError] = useState(false);
    const [ErrorRowList, setErrorRowList] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const saveButtonNotPermition = ["autosale"];
    const handleDateChange = (dates) => {
        if (dates) {
            setStartDate(dates[0]?.format("YYYY-MM-DD"));
            setEndDate(dates[1]?.format("YYYY-MM-DD"));
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    const navigate = useNavigate();

    const location = useLocation();

    let { key, pathWithoutSlash } = location.state || {};
    const pathnamecapital = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

    const gobackpage = () => {
        navigate("/" + `${pathWithoutSlash}`);
    };

    useEffect(() => {
        if (stockitemNameError && ledgerNameError) {
            let errordata = [...stockitemNameError, ...ledgerNameError];

            // Sort by id and remove duplicates by id
            errordata = errordata
                .sort((a, b) => a.id - b.id) // Sort by id in ascending order
                .filter(
                    (item, index, self) => index === self.findIndex((i) => i.id === item.id) // Keeps only the first occurrence of each id
                );

            // Set the unique error data
            setErrorRowList(errordata);
        }
    }, [stockitemNameError, ledgerNameError]);

    useEffect(() => {
        setIsLoading(true);
        viewAll();
    }, [subTableData, mainTableData]);

    let path;
    switch (pathWithoutSlash) {
        case "sales/excel":
            path = "salesdata";
            break;
        case "item/excel":
            path = "itemdata";
            break;
        case "ledgerbulk":
            path = "ledgerbulkdata";
            break;
        case "autoexpenses":
            path = "autoexpensesdata";
            break;
        default:
            path = `${pathWithoutSlash}data`;
            break;
    }
    const viewAll = async () => {
        setIsLoading(true);

        try {
            if (subTableData) {
                let Data = subTableData[path];
                // console.log("Data bf", Data);
                // console.log("key bf", key);

                Data = Data.filter((row) => {
                    return row.srno === key;
                });
                if (path === "autoexpensesdata") {
                    const parsedExpenses = JSON.parse(Data[0]?.expenses_name);

                    // Remove the `expenses_name` field from all items in Data
                    const commonData = Data.map(({ expenses_name, id, ...rest }) => rest)[0];

                    // Combine parsed expenses with the common data
                    Data = parsedExpenses.map(({ srNo, ...rest }) => ({
                        ...rest,
                        ...commonData,
                    }));
                }
                // console.log("Data af", Data);

                // if (pathWithoutSlash === "banking") {
                //     let forMergeVale = [];
                //     Data.forEach((entry) => {
                //         if (entry.issplit === "YES" && entry.primaryentry) {
                //             forMergeVale.push(entry);
                //         }
                //     });

                //     Data.forEach((entry) => {
                //         if (entry.issplit === "YES") {
                //             forMergeVale.forEach((splitentry) => {
                //                 if (entry.id === splitentry.primaryentry) {
                //                     entry.debit += splitentry.debit;
                //                     entry.credit += splitentry.credit;
                //                 }
                //             });
                //         }
                //     });

                //     Data = Data.filter((row) => {
                //         return row.primaryentry === null;
                //     });
                // }

                // if (pathWithoutSlash === "banking") {
                //     Data = Data.map(({ ...row }) => {
                //         const amount = row.debit > 0 ? row.debit : row.credit;
                //         const entries = Object.entries(row);
                //         entries.splice(8, 0, ["amount", amount]);
                //         return Object.fromEntries(entries);
                //     });
                // }
                if (pathWithoutSlash === "banking") {
                    // Step 1: Merge split entries
                    let forMergeVale = Data.filter((entry) => entry.issplit === "YES" && entry.primaryentry);

                    // Loop through Data to merge amounts for split entries
                    Data.forEach((entry) => {
                        if (entry.issplit === "YES") {
                            forMergeVale.forEach((splitentry) => {
                                if (entry.id === splitentry.primaryentry) {
                                    entry.debit += splitentry.debit;
                                    entry.credit += splitentry.credit;
                                }
                            });
                        }
                    });

                    // Step 2: Filter out entries with primaryentry != null
                    Data = Data.filter((row) => row.primaryentry === null);

                    // Step 3: Add "amount" field for each entry
                    Data = Data.map((row) => {
                        const amount = row.debit > 0 ? row.debit : row.credit;
                        return {
                            ...row,
                            amount, // Dynamically add "amount" field
                        };
                    });
                }

                Data = Data.map((obj, index) => ({
                    ...Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.replace(/_/g, " "), value])),
                }));

                setFullRowData(Data);
                setRowData(Data);

                const { totalCredit, totalDebit } = Data.reduce(
                    (totals, element) => {
                        if (element.srno === key) {
                            totals.totalCredit += element.credit;
                            totals.totalDebit += element.debit;
                        }
                        return totals;
                    },
                    { totalCredit: 0, totalDebit: 0 }
                );

                setCreditRs(totalCredit);
                setDebitRs(totalDebit);

                const getUniqueOptions = (data, key, filterKey, filterValue) => {
                    return [
                        ...new Set(
                            data?.[key]
                                ?.map((element) => (filterKey ? (element[filterKey] === filterValue ? element.name : null) : element.name))
                                .filter(Boolean)
                        ),
                    ];
                };

                // Ledger column options
                setLedgeroption(getUniqueOptions(subTableData, "ledgermaster"));

                // Ledger under "Purchase accounts"
                setPurchaseLedgerUnderPurchaseAccounts(getUniqueOptions(subTableData, "ledgermaster", "under", "Purchase accounts"));

                // Ledger under "Sales Accounts"
                setLedgerUnderSalesAccounts(getUniqueOptions(subTableData, "ledgermaster", "under", "Sales Accounts"));

                // Purchase voucher type options
                setPurchasevoucherType(getUniqueOptions(mainTableData, "vouchertype"));

                // Stock item column options
                setStockitemNameOptions(getUniqueOptions(subTableData, "stockitem"));

                // Party account name column options
                setPartyAcNameOptions(getUniqueOptions(subTableData, "partyaccount"));

                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
        } finally {
            // setIsLoading(false);
        }
    };

    const UpdateSelectedRow = () => {
        setIsLoading(true);
        let payload = {
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            tablename: path,
            data: upadateLedger,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/UpdateSelectedLedgerRow`, payload)
            .then((response) => {
                if (response.status === 200) {
                    fetchSubTableData();
                    // viewAll();
                    setUpadateLedger([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error:", error);
            });
    };

    const moreInfoBtn = () => {
        setTallySyncModalOpen(!tallySyncModalOpen);
    };

    const handleOpenSlpitMenu = (Slpitid) => {
        const Slpitiddata = rowData.find((data) => data.id === Slpitid);

        setSelectedSlpitIdData(Slpitiddata);
        setSplitModalOpen(true);
    };

    const handleOpenTallySyncTable = () => {
        if (selectedRowKeys.length === 0) {
            alert("Please select at least 1 row in order to push data to Tally");
            return;
        }

        console.log("selectedRow length", selectedRowKeys.length);
        console.log("selectedRow ", selectedRowKeys);

        console.log("ErrorRow length", ErrorRowList.length);

        const filterConditions = {
            autosale: (row) => row.flag === "NS",
            "sales/excel": (row) => row.flag === "NS" || row.flag === "SV",
            purchase: (row) => row.flag === "NS" || row.flag === "SV",
            banking: (row) => row.flag === "SV",
            autoexpenses: (row) => row.flag === "NS",
        };
        // console.log("pathWithoutSlash", pathWithoutSlash);

        const condition = filterConditions[pathWithoutSlash];
        if (!condition) return;

        let dataForSendToTally = [];
        let ledgerMissing = 0;

        selectedRowKeys.forEach((rowId) => {
            const selectedRow = rowData.find((row) => row.id === rowId.id && condition(row));

            if (selectedRow) {
                if (pathWithoutSlash === "sales/excel" && ErrorRowList.find((errorRow) => errorRow.id === selectedRow.id)) {
                    return; // Skip rows with errors for "sales/excel"
                }
                dataForSendToTally.push(selectedRow);
            } else {
                ledgerMissing++;
            }
        });

        console.log("SendToTally row length", dataForSendToTally.length);

        if (ledgerMissing === selectedRowKeys.length) {
            alert("All rows have missing or unavailable ledger or Type.");
            return;
        }

        if (dataForSendToTally.length === 0) {
            alert("All saved rows are already synced or no rows are saved.");
            return;
        }

        confirm({
            title: "Are you sure you want to push these entries to Tally?",
            okText: "Yes",
            cancelText: "No",
            async onOk() {
                try {
                    console.log("dataForSendToTally", dataForSendToTally);
                    setSelectedTallySyncId(dataForSendToTally);
                    setTallySyncModalOpen(true);
                } catch (e) {
                    console.error("Oops! An error occurred:", e);
                }
            },
            onCancel() {
                console.log("Action canceled by the user.");
            },
        });

        setSelectedRowKeys([]);
    };

    const handleOpenAutoExpensesTallySyncTable = () => {
        let dataForSendToTally = [];
        // setIsLoading(true);

        if (selectedRowKeys.length > 0) {
            selectedRowKeys.forEach((rowId) => {
                const selectedRow = rowData.find((row) => row.id === rowId.id && row.flag === "NS");
                if (selectedRow) dataForSendToTally.push(selectedRow);
            });

            if (dataForSendToTally.length === 0) {
                alert("All selected rows are already synced.");
                return;
            }

            Modal.confirm({
                title: "Are you sure you want to push these entries to Tally?",
                okText: "Yes",
                cancelText: "Cancel",
                async onOk() {
                    rowData.forEach((row) =>
                        dataForSendToTally.forEach((rowid) => {
                            if (row.id === rowid.id) {
                                row.flag = "PS";
                            }
                        })
                    );

                    let payload = {
                        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                        companyId: validatedProfile.companyId,
                        updateId: key,
                        data: rowData,
                        flag: "PS",
                    };
                    // console.log("payload", payload);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/update-autoexpenses`, payload)
                        .then((response) => {
                            if (response.status === 200) {
                                fetchSubTableData();
                                viewAll();
                                setSelectedRowKeys([]);
                                setSelectedTallySyncId(dataForSendToTally);
                                setTallySyncModalOpen(true);
                                // setIsLoading(false);
                            }
                        })
                        .catch((error) => {
                            // setIsLoading(false);
                            console.error("Error:", error);
                            setSelectedRowKeys([]);
                        });

                    setSelectedRowKeys([]);
                },
                onCancel() {
                    console.log("Operation canceled by the user.");
                },
            });
        } else {
            alert("Please select at least one row to push data to Tally.");
        }
    };

    const DeleteMultipleSelectedRow = () => {
        setIsLoading(true);
        let payload = {
            userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
            companyId: validatedProfile.companyId,
            tablename: path,
            deleteID: selectedRowKeys,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/deleteData`, payload)
            .then((response) => {
                if (response.status === 200) {
                    fetchSubTableData();
                    viewAll();
                    setSelectedRowKeys([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error:", error);
                setSelectedRowKeys([]);
            });
    };
    const [checkboxes, setCheckboxes] = useState({
        hideTallyRecords: false,
        savedRecords: false,
        blankRecords: false,
        failedRecords: false,
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: checked,
        }));

        if (name === "failedRecords") {
            if (checked) {
                // If checked, set to ErrorRowList
                setRowData(ErrorRowList);
            } else {
                // If unchecked, reset to fullRowData (or the default dataset)
                setRowData(fullRowData);
            }
        }
        if (name === "hideTallyRecords") {
            if (checked) {
                // If checked, set to ErrorRowList
                setRowData(ErrorRowList);
            } else {
                // If unchecked, reset to fullRowData (or the default dataset)
                setRowData(fullRowData);
            }
        }
    };

    //------------------------------------------------------

    const [PSItem, setPSItem] = useState(0);
    const [SNItem, setSNItem] = useState(0);
    const [SYItem, setSYItem] = useState(0);
    const [ERItem, setERItem] = useState(0);

    const viewAllsyncedFlag = () => {
        if (selectedTallySyncId.length > 0) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/subtabledata`, {
                    userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                    companyId: validatedProfile.companyId,
                })
                .then((response) => {
                    if (response.status === 200) {
                        let checkflagData;

                        if (pathWithoutSlash !== "autoexpenses") {
                            checkflagData = response.data.results[path];
                        } else {
                            if (response.data) {
                                console.log("respo ddd", response);

                                let data = response?.data?.results["autoexpensesdata"]?.filter((row) => row.srno === key);
                                checkflagData = JSON.parse(data[0]["expenses_name"]);
                            }
                        }
                        console.log("checkflagData ddd", checkflagData);

                        const PSItem = checkflagData.filter((data) =>
                            selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "PS")
                        );
                        const SNItem = checkflagData.filter((data) =>
                            selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "SN")
                        );
                        const SYItem = checkflagData.filter((data) =>
                            selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "SY")
                        );
                        const ERItem = checkflagData.filter((data) =>
                            selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "ER")
                        );

                        setPSItem(PSItem.length);
                        setSNItem(SNItem.length);
                        setSYItem(SYItem.length);
                        setERItem(ERItem.length);
                        console.log("PSItem", PSItem);
                        console.log("SNItem", SNItem);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };
    return (
        <>
            {isLoading && <Spinner isSpinner={isLoading} />}
            <div className="TransactionsTable">
                <div className="TransactionsTable-heading">
                    <div className="table-title">
                        <Button onClick={gobackpage}>
                            <ArrowLeftOutlined />
                        </Button>
                        <p className="subtable-title-name">
                            <SnippetsOutlined /> {pathnamecapital}
                        </p>
                        <span className="TransactionsNumber ">{rowData.length}</span>
                    </div>
                    <div className="table-company-title">
                        <span>Company Name:</span>
                        <p>{validatedProfile.companyName && validatedProfile.companyName.toUpperCase()}</p>
                    </div>
                </div>
                <div className="TransactionsTable-top-heading">
                    <div className="operations">
                        <div className="operations-inner">
                            <p>Bulk Operations </p>
                            <small>
                                <InfoCircleOutlined />
                            </small>
                        </div>
                        <span>Update Bulk Records</span>
                        <input type="file" name="" id="" />
                    </div>
                    <div className="filters">
                        <p>
                            General Filters{" "}
                            <small>
                                <InfoCircleOutlined />
                            </small>
                        </p>

                        <div className="filters-checkbox">
                            <div className="checkbox">
                                <Input
                                    type="checkbox"
                                    name="hideTallyRecords"
                                    id="hide-records"
                                    checked={checkboxes.hideTallyRecords}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="hide-records">Hide Tally Synced Records</label>
                            </div>
                            <div className="checkbox">
                                <Input
                                    type="checkbox"
                                    name="savedRecords"
                                    id="saved-records"
                                    checked={checkboxes.savedRecords}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="saved-records">Saved Records</label>
                            </div>
                            <div className="checkbox">
                                <Input
                                    type="checkbox"
                                    name="blankRecords"
                                    id="blank-records"
                                    checked={checkboxes.blankRecords}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="blank-records">Blank Records</label>
                            </div>
                            <div className="checkbox">
                                <Input
                                    type="checkbox"
                                    name="failedRecords"
                                    id="failed-records"
                                    checked={checkboxes.failedRecords}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="failed-records">Failed Records</label>
                            </div>
                        </div>
                        <div className="filters-date">
                            <div className="date-filter">
                                <DatePicker.RangePicker onChange={handleDateChange} />
                            </div>

                            {pathWithoutSlash === "banking" ? (
                                <div className="creditdebit">
                                    <span className="credit-Rs Rs-text ">credit:</span>
                                    <p className="credit-amount">
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(creditRs)}
                                    </p>
                                    <span className="debit-Rs Rs-text">debit:</span>
                                    <p className="debit-amount">
                                        {new Intl.NumberFormat("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }).format(debitRs)}
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="controal-btn">
                        <div className="controal-btn-line">
                            <div className="moreinfo-btn-menu all-btn">
                                <Button onClick={moreInfoBtn}>
                                    <InfoCircleOutlined />
                                    More info
                                </Button>
                            </div>

                            <div className="create-btn-menu all-btn">
                                <Button>
                                    <InfoCircleOutlined />
                                    Create Ledger
                                </Button>
                            </div>
                            {!saveButtonNotPermition.includes(pathWithoutSlash) && (
                                <div className=" all-btn">
                                    <Button type="primary" onClick={UpdateSelectedRow}>
                                        Save
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className="controal-btn-line">
                            {pathWithoutSlash === "autoexpenses" ? (
                                <div className="save-btn-menu all-btn">
                                    <Button onClick={handleOpenAutoExpensesTallySyncTable}>Send to Tally</Button>
                                </div>
                            ) : (
                                <div className="save-btn-menu all-btn">
                                    <Button onClick={handleOpenTallySyncTable}>Send to Tally</Button>
                                </div>
                            )}

                            <div className="all-btn">
                                <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-danger bg-btn all-btn">
                                    <YoutubeOutlined className="topobar-icon text-danger " />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <AgGridSubTable
                    role={role}
                    oldPathWithoutSlash={pathWithoutSlash}
                    RowKey={key}
                    path={path}
                    setUpadateLedger={setUpadateLedger}
                    viewAll={viewAll}
                    ledgeroption={ledgeroption}
                    purchasevoucherType={purchasevoucherType}
                    purchaseLedgerUnderPurchaseAccounts={purchaseLedgerUnderPurchaseAccounts}
                    ledgerUnderSalesAccounts={ledgerUnderSalesAccounts}
                    stockitemNameOptions={stockitemNameOptions}
                    partyAcNameOptions={partyAcNameOptions}
                    rowData={rowData}
                    selectedSlpitIdData={selectedSlpitIdData}
                    handleOpenSlpitMenu={handleOpenSlpitMenu}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    startDate={startDate}
                    endDate={endDate}
                    setLedgerNameError={setLedgerNameError}
                    setStockitemNameError={setStockitemNameError}
                    stockitemNameError={stockitemNameError}
                    ledgerNameError={ledgerNameError}
                />

                {selectedRowKeys.length > 1 && role && ["D", "F"].includes(role["/" + `${pathWithoutSlash}`]) ? (
                    <Button type="primary" className="TransactionsTable-delete-btn" onClick={DeleteMultipleSelectedRow}>
                        Delete Row
                    </Button>
                ) : (
                    <Button type="primary" className="TransactionsTable-delete-btn" onClick={DeleteMultipleSelectedRow} disabled>
                        Delete Row
                    </Button>
                )}
            </div>
            {splitModalOpen ? (
                <SpliteTable
                    splitModalOpen={splitModalOpen}
                    fetchSubTableData={fetchSubTableData}
                    validatedProfile={validatedProfile}
                    setSplitModalOpen={setSplitModalOpen}
                    pathPlusData={path}
                    ledgeroption={ledgeroption}
                    selectedSlpitIdData={selectedSlpitIdData}
                    viewAll={viewAll}
                />
            ) : (
                ""
            )}

            {tallySyncModalOpen ? (
                <TallySyncTable
                    tallySyncModalOpen={tallySyncModalOpen}
                    validatedProfile={validatedProfile}
                    setTallySyncModalOpen={setTallySyncModalOpen}
                    pathPlusData={path}
                    selectedTallySyncId={selectedTallySyncId}
                    fetchSubTableData={fetchSubTableData}
                    PSItem={PSItem}
                    SNItem={SNItem}
                    SYItem={SYItem}
                    ERItem={ERItem}
                    viewAllsyncedFlag={viewAllsyncedFlag}
                />
            ) : (
                ""
            )}

            {(stockitemNameError.length > 0 || ledgerNameError.length > 0) && pathWithoutSlash === "sales/excel" ? (
                <ErrorModel stockitemNameError={stockitemNameError} ledgerNameError={ledgerNameError} validatedProfile={validatedProfile} />
            ) : (
                ""
            )}
        </>
    );
};

export default withProfile(withRole(TransactionsTable));
