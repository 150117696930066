import React, { useState } from "react";

import axios from "axios";
import { load } from "@cashfreepayments/cashfree-js";
import { useLocation, useNavigate } from "react-router-dom";
import { withProfile } from "../../../../../contexts/profileContext";

const CheckoutPage = ({ validatedProfile, setValidatedProfile }) => {
  const location = useLocation();
  const { planValue } = location.state;

  const redirectUrl = `${window.location.origin}` + "/payment-status";
  const [billingDetails, setBillingDetails] = useState({
    plan: planValue,
    users: 0,
    company: 0,
    number: validatedProfile.mobile,
    firstName: validatedProfile.name.split(" ")[0] || "",
    lastName: validatedProfile.name.split(" ")[1] || "",
    email: validatedProfile.email,
    discountCode: "",
    total: 0,
    userId: validatedProfile.userid,
    url: redirectUrl,
    orderid: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setBillingDetails((prev) => {
      const updatedDetails = { ...prev, [name]: value };

      if (name === "plan" && value === "10000") {
        updatedDetails.company = 0; // Reset company when plan is 10000
      }

      return updatedDetails;
    });
  };

  const validateForm = () => {
    const { firstName, lastName, email, plan } = billingDetails;
    if (!firstName || !lastName || !email || !plan) {
      alert("Please fill all required fields.");
      return false;
    }
    return true;
  };

  const calculateTotal = () => {
    const planPrice = parseInt(billingDetails.plan, 10);
    const users = parseInt(billingDetails.users, 10) || 0;
    const company = billingDetails.plan !== "10000" ? parseInt(billingDetails.company, 10) || 0 : 0;

    const subtotal = 500 * users;
    const subcompany = 500 * company;
    const discount = billingDetails.discountCode === "SAVE10" ? ((planPrice + subtotal + subcompany) * 10) / 100 : 0;

    const total = planPrice + subtotal + subcompany - discount || 0;
    billingDetails["total"] = total;
    billingDetails["discount"] = discount;
    return { subtotal, subcompany, discount, total };
  };

  const handleSubmit = async () => {
    console.log("redirectUrl", redirectUrl);
    if (validateForm()) {
      billingDetails.orderid = `ORDER-${Date.now()}`;
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/updatePaymentDetails`, billingDetails, { headers: { "Content-Type": "application/json" } });

        console.log("response", response);
        if (response.status === 200) {
          setValidatedProfile({ ...validatedProfile, payment_method_id: response.data.orderid });

          let cashfree;
          var initializeSDK = async function () {
            cashfree = await load({
              mode: "sandbox",
            });
          };
          await initializeSDK();

          let checkoutOptions = {
            paymentSessionId: response.data.paymentSessionId,
            redirectTarget: "_blank",
          };

          cashfree.checkout(checkoutOptions);
        }
      } catch (error) {
        alert("An error occurred during payment submission.");
      }
    }
  };

  const { subtotal, subcompany, discount, total } = calculateTotal();

  return (
    <>
      <div className="checkout_div">
        <div className="checkout_div_inner">
          <div className="plan_div_heading">
            <p className="heading_title">Checkout</p>
          </div>
          {/* Body */}
          <div className="body_div_outer">
            <div className="payment_container_div">
              <div className="body_div">
                <div className="billing_details_div checkout_card">
                  <p className="card_title">Billing details</p>

                  <div className="input_div">
                    <label>Select Plan</label>
                    <select name="plan" value={billingDetails.plan} onChange={handleInputChange}>
                      <option value="">Select your plan</option>
                      <option value="5000">5000 Rs with 5 companies and 3 users</option>
                      <option value="10000">10000 Rs with unlimited companies and 3 users</option>
                    </select>
                  </div>

                  <div className="input_div_flax">
                    <div className="input_div">
                      <label>Add User</label>
                      <input
                        name="users"
                        type="number"
                        value={billingDetails.users}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0) {
                            handleInputChange(e);
                          }
                        }}
                        placeholder="Enter number of users"
                        min="0"
                      />
                    </div>
                    {billingDetails.plan !== "10000" && (
                      <div className="input_div">
                        <label>Add Company</label>
                        <input
                          name="company"
                          type="number"
                          value={billingDetails.company}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0) {
                              handleInputChange(e);
                            }
                          }}
                          placeholder="Enter number of companies"
                          min="0"
                        />
                      </div>
                    )}
                  </div>

                  <div className="input_div">
                    <label>Phone Number</label>
                    <input name="number" value={billingDetails.number} onChange={handleInputChange} placeholder="Enter your phone number" />
                  </div>

                  <div className="input_div_flax">
                    <div className="input_div">
                      <label>First Name *</label>
                      <input name="firstName" value={billingDetails.firstName} onChange={handleInputChange} placeholder="Enter your first name" />
                    </div>
                    <div className="input_div">
                      <label>Last Name *</label>
                      <input name="lastName" value={billingDetails.lastName} onChange={handleInputChange} placeholder="Enter your last name" />
                    </div>
                  </div>

                  <div className="input_div">
                    <label>Email Address *</label>
                    <input name="email" type="email" value={billingDetails.email} onChange={handleInputChange} placeholder="Enter your email address" />
                  </div>
                </div>

                {/* Order Summary */}
                <div className="your_order_div checkout_card">
                  <p className="card_title">Your order</p>
                  <div className="product_div">
                    <div className="item_div item_dark_div">
                      <p>Product</p>
                      <p>Subtotal</p>
                    </div>

                    <div className="item_div">
                      <div className="plan_text">
                        <p className="dark_plan_text">{billingDetails.plan === "5000" ? "Diamond" : "Platinum"}</p>
                        <p>{billingDetails.plan === "5000" ? "5 companies and 3 users" : "Unlimited companies and 3 users"}</p>
                      </div>
                      <p className="dark_plan_text">Rs. {Number(billingDetails.plan).toFixed(2)}</p>
                    </div>

                    {/* {subtotal > 0 && ( */}
                    <div className="item_div">
                      <p>Add More {billingDetails.users} User(s)</p>
                      <p className="dark_plan_text">Rs. {subtotal.toFixed(2)}</p>
                    </div>
                    {/* )} */}

                    {/* {subcompany > 0 && ( */}
                    <div className="item_div">
                      <p>Add More {billingDetails.company} company(s)</p>
                      <p className="dark_plan_text">Rs. {subcompany.toFixed(2)}</p>
                    </div>
                    {/* )} */}

                    <div className="item_div">
                      <p>Discount</p>
                      <p className="dark_plan_text">Rs. {discount.toFixed(2)}</p>
                    </div>

                    <div className="item_div item_dark_div">
                      <p className="dark_plan_text">Total</p>
                      <p className="dark_plan_text">Rs. {total.toFixed(2)} </p>
                    </div>

                    <div className="item_btn_div">
                      <input name="discountCode" value={billingDetails.discountCode} onChange={handleInputChange} placeholder="Enter discount code SAVE10" />
                      <button onClick={() => alert("Discount applied if valid")}>Apply</button>
                    </div>
                  </div>

                  <span>By clicking on the "Confirm Order" button, you agree to our Terms and Conditions, Privacy Policy, and Refund Policy.</span>

                  <button className="confirm_order_btn" onClick={handleSubmit}>
                    Confirm Order
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="footer_div">
            <p>Copyrights © Tax-Link 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withProfile(CheckoutPage);

