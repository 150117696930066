import React, { useEffect, useState } from "react";
import { Button, Modal, Input, List } from "antd";
import { CloseOutlined, BellOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { withProfile } from "../../../contexts/profileContext";

const notyficationType = ["Document", "Client", "System"];
const type_data = {
    Document: [
        {
            time: "November 11th, 2024, 5:49:07 pm",
            text: "SBI CURRENT.pdf extract successfully for KAGZI INFOTECH (10000).",
        },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
        { time: "November 11th, 2024, 5:49:07 pm", text: "DENA CURRENT.pdf for KAGZI INFOTECH (10000)." },
    ],
    Client: [
        {
            time: "November 11th, 2024, 5:49:07 pm",
            text: "BOB CURRENT.pdf extract successfully for KAGZI INFOTECH (10000).",
        },
        { time: "November 11th, 2024, 5:49:07 pm", text: "HDFC CURRENT.pdf extract successfully for KAGZI INFOTECH (10000)." },
    ],
    System: [
        {
            time: "November 11th, 2024, 5:49:07 pm",
            text: "AXIS CURRENT.pdf extract successfully for KAGZI INFOTECH (10000).",
        },
        { time: "November 11th, 2024, 5:49:07 pm", text: "LOCAL CURRENT.pdf extract successfully for KAGZI INFOTECH (10000)." },
    ],
};

const NotificationDropdown = ({ setValidatedProfile, validatedProfile }) => {
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notyficationContent, setNotyficationContent] = useState(type_data["Document"]);
    const [activeType, setActiveType] = useState(0);
    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const allReadButtonClick = () => {
        alert("All read");
    };

    const handleCancel = () => {
        setDropdownVisible(false);
    };
    const setNotyType = (type, index) => {
        console.log("type", type, index);

        setActiveType(index);
        setNotyficationContent(type_data[type]);
    };

    return (
        <>
            <span onClick={handleButtonClick} className="company-icon header-icon-pd">
                <BellOutlined className="icon-header-notification" />
            </span>

            <Modal
                className="companylist-model"
                open={dropdownVisible}
                okButtonProps={{ style: { display: "none" } }}
                onCancel={handleCancel}
            >
                <div className="company-dropdown-outer">
                    <div className="company-dropdown bg-white">
                        <div className="dropdown-body">
                            <div className="dropdown-body-content">
                                <div className="dropdown-body-header">
                                    <div className="company_heading_andClose">
                                        <span className="close_icon_btn" onClick={handleCancel}>
                                            <CloseOutlined />
                                        </span>
                                        <p>Notifications</p>
                                    </div>
                                    <Button type="primary" className="company_manage_btn" onClick={allReadButtonClick}>
                                        Mark all as read
                                    </Button>
                                </div>

                                <div className="notifications_body_div">
                                    <div className="notifications_type_div">
                                        {notyficationType.map((type, index) => {
                                            return (
                                                <a
                                                    key={index}
                                                    className={`notifications_type_btn ${activeType === index ? "activetype" : ""} `}
                                                    onClick={() => setNotyType(type, index)}
                                                >
                                                    {type}
                                                </a>
                                            );
                                        })}
                                    </div>

                                    <div className="notifications_list">
                                        <div className="notifications_list_inner">
                                            <ul>
                                                <SimpleBar style={{ height: "85vh" }}>
                                                    {notyficationContent.map((noty, index) => {
                                                        return (
                                                            <li className="noty_li" key={index}>
                                                                <span className="noty_time">{noty.time}</span>
                                                                <p className="noty_text">{noty.text}</p>
                                                            </li>
                                                        );
                                                    })}
                                                </SimpleBar>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_button_div"></div>
            </Modal>
        </>
    );
};

export default withProfile(NotificationDropdown);
