import React, { createContext, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import axios from "axios";

const ProfileContext = createContext();
const secretKey = "L3j*8k!2O#dM9wQ4T$7eV6rX0fN1pG@5";

const decryptProfile = (encryptedProfile) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedProfile, secretKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error("Error decrypting profile data:", error);
        return null;
    }
};

const encryptProfile = (profile) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(profile), secretKey).toString();
    } catch (error) {
        console.error("Error encrypting profile data:", error);
        return null;
    }
};

const processMainTableData = (data) => {
    data["sales/excel"] = data["sales"];
    data["item/excel"] = data["item"];
    data["item"] = data["stockitem"];
    data["stockitem"] = [];
    return data;
};

const ProfileProvider = ({ children }) => {
    const [validatedProfile, setValidatedProfile] = useState(() => {
        const encryptedProfile = Cookies.get("validatedProfile");
        return encryptedProfile ? decryptProfile(encryptedProfile) : null;
    });

    const [mainTableData, setMainTableData] = useState();
    const [subTableData, setSubTableData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");

    useEffect(() => {
        if (validatedProfile) {
            const encryptedProfile = encryptProfile(validatedProfile);
            if (encryptedProfile) {
                Cookies.set("validatedProfile", encryptedProfile, { expires: 7 });
            }
        }
    }, [validatedProfile]);

    useEffect(() => {
        if (validatedProfile?.companyId) {
            fetchData();
        }
    }, [validatedProfile]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            await fetchMainTableData();
            setIsLoading(false);
            await fetchSubTableData();
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchMainTableData = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/maintabledata`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
            });

            if (status === 200) {
                const processedData = processMainTableData(data.results);
                setMainTableData(processedData);
            }
        } catch (error) {
            console.error("Error fetching main table data:", error);
        }
    };

    const fetchSubTableData = async () => {
        try {
            const { data, status } = await axios.post(`${process.env.REACT_APP_API_URL}/subtabledata`, {
                userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                companyId: validatedProfile.companyId,
            });

            if (status === 200) {
                setSubTableData(data.results);
            }
        } catch (error) {
            console.error("Error fetching sub table data:", error);
        }
    };

    const isActive = () => !isEmpty(validatedProfile);

    const handleSearch = (e) => {
        setSearchKeyword(e.target.value);
    };

    // console.log("subTableData", subTableData);

    return (
        <ProfileContext.Provider
            value={{
                handleSearch,
                searchKeyword,
                mainTableData,
                fetchData,
                fetchMainTableData,
                isLoading,
                setIsLoading,
                subTableData,
                fetchSubTableData,
                isActive,
                validatedProfile,
                setValidatedProfile,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};

const withProfile = (Child) => (props) =>
    <ProfileContext.Consumer>{(context) => <Child {...props} {...context} />}</ProfileContext.Consumer>;

export { ProfileProvider, withProfile, ProfileContext };
