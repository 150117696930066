import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, SnippetsOutlined, YoutubeOutlined, SettingOutlined } from "@ant-design/icons";
import { Switch, Button, Steps, Select, Input, Checkbox, Radio } from "antd";
import { navigate, useLocation, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import axios from "axios";
import { withProfile } from "../../contexts/profileContext";

const INITIAL_TALLY_MAPPED_TABLES = {
    sales_local: [
        "referance no",
        "invoice date",
        "party a/c name",
        "place of supply",
        "state of supply",
        "sales ledger",
        "name of item",
        "quantity",
        "amount",
        "rate",
    ],
    sales_export: [
        "referance no",
        "invoice date",
        "party a/c name",
        "place of supply",
        "sales ledger",
        "name of item",
        "quantity",
        "amount",
        "rate",
    ],
    purchase: [
        "supplier inv no",
        "invoice date",
        "party a/c name",
        "place of supply",
        "purchase ledger",
        "name of item",
        "quantity",
        "amount",
        "gst no",
        "rate",
    ],
};

const initialUnmapedTable = ["sgst", "cgst", "igst", "total amount"];

// second page data
const secondPageTaxLedger = ["sgst ledger", "cgst ledger", "igst ledger"];
const ledgerInTally = ["sgst", "cgst", "igst"];
const roundOffTallyFild = ["round-off ledger"];

const Mapping = ({ validatedProfile, mainTableData, fetchData }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [lutExportToggal, setLutExportToggal] = useState(false); // Toggle button for country and state column

    const [mapedTallyTable, setMapedTallyTable] = useState([]); // 1 page tally list
    const [mapedSheetTable, setMapedSheetTable] = useState([]); // 1 page sheet list
    const [mapping, setMapping] = useState({}); // State to store key-value mappings
    const [ledgeroption, setLedgeroption] = useState([]);
    const [ledgerUnderDutiesTaxes, setLedgerUnderDutiesTaxes] = useState([]);

    const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false); // State to manage checkbox value

    const { columnheading, pathWithoutSlash, pathOnlyName, json, fileName, excelType } = location.state || {};

    useEffect(() => {
        if (mainTableData) {
            // for make ledger option
            const newLedgerOptions = mainTableData["ledgermaster"]?.map((element) => element.name) || []; // for dropdown in ledger column
            const uniqueLedgerOptions = [...new Set(newLedgerOptions)];
            setLedgeroption(uniqueLedgerOptions);

            const newLedgerUnderDutiesTaxes =
                mainTableData["ledgermaster"]?.map((element) => {
                    if (element.under === "Duties & Taxes") {
                        return element.name;
                    }
                }) || []; // for dropdown in ledger column
            const uniqueLedgerUnderDutiesTaxes = [...new Set(newLedgerUnderDutiesTaxes)];
            setLedgerUnderDutiesTaxes(uniqueLedgerUnderDutiesTaxes);
            console.log("mainTableData", mainTableData["ledgermaster"]);
        }
    }, [mainTableData]);

    const steps = [
        {
            title: "",
            content: "First-content",
        },
        {
            title: "",
            content: "Second-content",
        },
        {
            title: "",
            content: "Second-content",
        },
    ];
    const onChangeInvoice = (checked) => {
        console.log(`Switch to ${checked}`);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const nextPage = () => {
        const isOnFirstPage = currentPage === 1;
        const needsRoundOffSelection = isOnFirstPage && isRoundOffEnabled && !roundOFf;

        if (needsRoundOffSelection) {
            alert("Select Round-Off Ledger");
            return;
        }

        setCurrentPage(currentPage + 1);
    };

    const previousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const gobackpage = () => {
        navigate("/" + `${pathWithoutSlash}`);
    };
    //--------------------------- first page code --------------------------------------------------------------------------
    function splitColumnHeadings(mappedTables, columnHeadings) {
        const mappedSet = new Set(mappedTables);
        const splitMaped = [];
        const splitUnmaped = [];

        // Split columnHeadings into sales_export and initialUnmapedTable
        columnHeadings.forEach((heading) => {
            if (mappedSet.has(heading)) {
                splitMaped.push(heading);
            } else {
                splitUnmaped.push(heading);
            }
        });

        return { splitMaped, splitUnmaped };
    }

    // Sort the headings as per their order in the source array
    function assortColumnHeadings(mappedTables, columnHeadings) {
        return mappedTables.map((mappedHeading) => columnHeadings.find((heading) => heading === mappedHeading));
    }

    useEffect(() => {
        if (pathWithoutSlash === "sales/excel") {
            setMapedTallyTable(lutExportToggal ? INITIAL_TALLY_MAPPED_TABLES.sales_local : INITIAL_TALLY_MAPPED_TABLES.sales_export);
        }
        if (pathWithoutSlash === "purchase") {
            setMapedTallyTable(INITIAL_TALLY_MAPPED_TABLES.purchase);
        }
    }, [lutExportToggal, pathWithoutSlash]);

    useEffect(() => {
        // const { splitMaped } = splitColumnHeadings(mapedTallyTable, columnheading);
        // const columnHeadingsMapped = assortColumnHeadings(mapedTallyTable, splitMaped);
        // setMapedSheetTable(columnHeadingsMapped);
        setMapedSheetTable(columnheading);
    }, [mapedTallyTable, columnheading]);

    useEffect(() => {
        const mappedObject = mapedTallyTable.reduce((acc, key, index) => {
            acc[key] = mapedSheetTable[index] || null; // If mapedSheetTable has fewer items, assign null
            return acc;
        }, {});
        setMapping(mappedObject);
    }, [mapedTallyTable, mapedSheetTable]);

    const handleMappingChange = (event, keyIndex) => {
        console.log("event.target", event);

        const selectedValue = event;
        const selectedKey = mapedTallyTable[keyIndex];

        setMapping((prevMapping) => ({
            ...prevMapping,
            [selectedKey]: selectedValue,
        }));
    };

    const [unmapedTallyTable, setUnmapedTallyTable] = useState([]); // 1 page unmaped tally list
    const [unmapedSheetTable, setUnmapedSheetTable] = useState([]); // 1 page unmaped sheet list
    const [unmapping, setUnmapping] = useState({}); // State to store key-value mappings

    useEffect(() => {
        if (pathWithoutSlash === "sales/excel") {
            setUnmapedTallyTable(initialUnmapedTable);
        }
        if (pathWithoutSlash === "purchase") {
            setUnmapedTallyTable(initialUnmapedTable);
        }
    }, [pathWithoutSlash]);

    useEffect(() => {
        // const { splitUnmaped } = splitColumnHeadings(mapedTallyTable, columnheading);

        // const columnHeadingsUnmapped = assortColumnHeadings(splitUnmaped, columnheading);

        // setUnmapedSheetTable(columnHeadingsUnmapped);
        setUnmapedSheetTable(columnheading);
    }, [unmapedTallyTable, columnheading]);

    useEffect(() => {
        const mappedObject = unmapedTallyTable.reduce((acc, key, index) => {
            acc[key] = unmapedSheetTable[index] || null; // If mapedSheetTable has fewer items, assign null
            return acc;
        }, {});
        setUnmapping(mappedObject);
    }, [unmapedTallyTable, unmapedSheetTable]);

    const handleUnmappingChange = (event, keyIndex) => {
        const selectedValue = event;
        const selectedKey = unmapedTallyTable[keyIndex];

        setUnmapping((prevMapping) => ({
            ...prevMapping,
            [selectedKey]: selectedValue,
        }));
    };
    //--------------------------- second page code --------------------------------------------------------------------------
    const [ledgerSheetNo, setLedgerSheetNo] = useState({}); // State to store key-value mappings
    const handleSecondPageTaxLedger = (event, keyIndex, row) => {
        const selectedValue = event;

        setLedgerSheetNo((prevMapping) => ({
            ...prevMapping,
            [row]: selectedValue,
        }));
    };

    const [gstFromExcel, setGstFromExcel] = useState("No");
    const [gstAutoCalculation, setGstAutoCalculation] = useState("Yes");

    const handleExcelChange = (e) => {
        setGstFromExcel(e.target.value);
        if (e.target.value === "Yes") {
            setGstAutoCalculation("Yes");
        }
    };

    const handleAutoCalculationChange = (e) => {
        setGstAutoCalculation(e.target.value);
    };

    const [roundOFf, setRoundOFf] = useState(); // State to store key-value mappings

    const handleRoundOffCheckboxChange = (event) => {
        setIsRoundOffEnabled(event.target.checked); // Update state based on checkbox value
    };

    const roundOFfChange = (event, keyIndex) => {
        setRoundOFf({ isroundoff: event });
    };

    const [excelSheeSelectedCheckboxes, setExcelSheeSelectedCheckboxes] = useState([]);

    const handleExcelSheeCheckboxChange = (event, rowKey, headerName) => {
        const isChecked = event.target.checked;
        const newSelectedCheckboxes = { ...excelSheeSelectedCheckboxes };

        if (isChecked) {
            newSelectedCheckboxes[headerName] = rowKey;
        } else {
            delete updatedCheckboxes[headerName];
        }
        newSelectedCheckboxes[unmapedTallyTable[unmapedTallyTable.length - 1]] = mapedSheetTable[mapedSheetTable.length - 1];

        setExcelSheeSelectedCheckboxes(newSelectedCheckboxes);
    };

    const [gstAutoSelectedCheckboxes, setGstAutoSelectedCheckboxes] = useState([]);

    const handleGstAutoCheckboxChange = (event, rowKey, headerName) => {
        const isChecked = event.target.checked;
        const updatedCheckboxes = { ...gstAutoSelectedCheckboxes }; // Create a copy of the current object

        if (isChecked) {
            updatedCheckboxes[headerName] = rowKey;
        } else {
            delete updatedCheckboxes[headerName];
        }
        updatedCheckboxes[unmapedTallyTable[unmapedTallyTable.length - 1]] = unmapedSheetTable[unmapedSheetTable.length - 1];

        setGstAutoSelectedCheckboxes(updatedCheckboxes); // Update the state with the modified object
    };

    //--------------------------- third  page code --------------------------------------------------------------------------

    const mappingarraymearge = () => {
        let newArray;
        if (gstAutoCalculation === "No") {
            newArray = { ...mapping, ...gstAutoSelectedCheckboxes };
        } else if (gstFromExcel === "Yes") {
            newArray = { ...mapping, ...excelSheeSelectedCheckboxes };
        } else {
            newArray = { ...mapping };
        }
        // console.log("JSON Data:", json);
        // console.log("newArray Data:", newArray);

        function updateKeys(json, newArray) {
            let allRowData = {};
            const mapKeys = (json) => {
                let updatedData = {};

                for (let originalKey in newArray) {
                    const mappedKey = newArray[originalKey];
                    originalKey = originalKey.toLowerCase();
                    if (json.hasOwnProperty(mappedKey)) {
                        updatedData[originalKey] = json[mappedKey];
                    } else {
                        updatedData[originalKey] = null;
                    }
                }

                // auto gst calculation from stockitem
                if (gstAutoCalculation === "Yes") {
                    mainTableData?.itemgstdetails.map((row) => {
                        if (row.itemName === updatedData["name of item"]) {
                            const rate = Number(updatedData.rate) || 0;
                            const sgstPercent = Number(row.sgst) || 0;
                            const cgstPercent = Number(row.cgst) || 0;
                            const igstPercent = Number(row.igst) || 0;

                            if (updatedData["place of supply"] === validatedProfile?.companyState) {
                                updatedData["sgst"] = Number(((rate * sgstPercent) / 100).toFixed(2));
                                updatedData["cgst"] = Number(((rate * cgstPercent) / 100).toFixed(2));
                                updatedData["igst"] = 0;
                                updatedData["total amount"] = (rate + updatedData["sgst"] + updatedData["cgst"]).toFixed(2);
                            } else {
                                updatedData["sgst"] = 0;
                                updatedData["cgst"] = 0;
                                updatedData["igst"] = Number(((rate * igstPercent) / 100).toFixed(2));
                                updatedData["total amount"] = (rate + updatedData["igst"]).toFixed(2);
                            }
                        }
                    });
                }
                if (gstFromExcel === "No") {
                    updatedData = { ...updatedData, ...ledgerSheetNo };
                    console.log("updatedData", updatedData);
                }
                // roundoff calculation from in total amount
                if (isRoundOffEnabled && roundOFf) {
                    const roundedAmount = Math.round(updatedData["total amount"]);

                    // Calculate the roundoff value and ensure it is always negative if needed
                    let roundoffvalue = (updatedData["total amount"] - roundedAmount).toFixed(2);
                    roundoffvalue = parseFloat(roundoffvalue);

                    // Ensure roundoffvalue is negative
                    if (roundoffvalue > 0) {
                        roundoffvalue = -Math.abs(roundoffvalue);
                        updatedData.roundoffvalue = roundoffvalue;
                    } else {
                        roundoffvalue = Math.abs(roundoffvalue);
                        updatedData.roundoffvalue = roundoffvalue;
                    }

                    // add roundOFf when checkbox active
                    updatedData = { ...updatedData, ...roundOFf };
                }

                if (excelType === "Multiple") {
                    const referenceNo = updatedData["referance no"];
                    const partyAccountName = updatedData["party a/c name"];
                    const salesLedger = updatedData["sales ledger"];
                    const placeOfSupply = updatedData["place of supply"];
                    const stateOfSupply = updatedData["state of supply"];
                    const invoiceDate = updatedData["invoice date"];

                    if (allRowData[referenceNo] != undefined) {
                        updatedData["party a/c name"] = allRowData[referenceNo].partyAccountName;
                        updatedData["sales ledger"] = allRowData[referenceNo].salesLedger;
                        updatedData["place of supply"] = allRowData[referenceNo].placeOfSupply;
                        updatedData["state of supply"] = allRowData[referenceNo].stateOfSupply;
                        updatedData["invoice date"] = allRowData[referenceNo].invoiceDate;
                    } else {
                        allRowData = {
                            ...allRowData,
                            [referenceNo]: {
                                partyAccountName,
                                salesLedger,
                                placeOfSupply,
                                stateOfSupply,
                                invoiceDate,
                            },
                        };
                    }
                }
                return {
                    ...updatedData,
                    [pathOnlyName === "sales" ? "sales_type" : "purchase_type"]: pathOnlyName === "sales" ? "Sales" : "Purchase",
                };
            };

            const updatedJsonData = json.map(mapKeys);

            return updatedJsonData;
        }

        const updatedJsonData = updateKeys(json, newArray);

        const uploadToDatabase = async () => {
            try {
                let data = {
                    userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
                    companyId: validatedProfile.companyId,
                    fileItemType: excelType,
                };
                if (pathOnlyName === "sales") {
                    data[pathOnlyName + "data"] = updatedJsonData;
                    if (lutExportToggal) {
                        data[pathOnlyName] = [{ name: fileName, type: "LUT EXPORT" }];
                    } else {
                        data[pathOnlyName] = [{ name: fileName, type: "LOCAL" }];
                    }
                }

                if (pathOnlyName === "purchase") {
                    data[pathOnlyName + "data"] = updatedJsonData;
                    data[pathOnlyName] = [{ name: fileName }];
                }

                console.log("data", data);
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertfileapi`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                fetchData();
                navigate("/transactionstable", {
                    state: { key: response.data.result.key, pathWithoutSlash },
                });
            } catch (error) {
                alert(error.response.data.message ? error.response.data.message : "Error in uploading data");
            }
        };

        uploadToDatabase();
    };

    return (
        <>
            <div className="mapping-outer">
                <div className="mapping-inner">
                    <div className="mapping-heading-outer">
                        <div className="mapping-heading">
                            <div className="beck-btn">
                                <Button onClick={gobackpage}>
                                    <ArrowLeftOutlined />
                                </Button>
                                <p>
                                    <SnippetsOutlined /> Map Fields
                                </p>
                            </div>

                            <div className="Steps-div">
                                <Steps current={currentPage} className="autosaleModel_steps">
                                    {steps.map((item) => (
                                        <Steps.Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                            </div>

                            <div className="invoice-btn">
                                <label htmlFor="" className="invoice-common">
                                    Accounting Invoice
                                </label>
                                <Switch className="invoice-common" defaultChecked onChange={onChangeInvoice} />
                                <label htmlFor="" className="invoice-common">
                                    Item Invoice
                                </label>
                                <Button className="invoice-common Configration-btn" type="primary">
                                    <SettingOutlined /> Configration
                                </Button>
                                <button className="invoice-common mapping-ytb-btn btn-outline-danger bg-btn">
                                    <YoutubeOutlined className="text-danger" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mapping-body-outer">
                        {pathOnlyName === "sales" && currentPage === 0 ? (
                            <div className="export-toggle-container">
                                <span>LOCAL </span>
                                <Switch
                                    className="export-toggal-switch"
                                    defaultChecked={lutExportToggal}
                                    onChange={() => setLutExportToggal(!lutExportToggal)}
                                />
                                <span> LUT EXPORT</span>
                            </div>
                        ) : null}
                        {currentPage === 0 && (
                            <div className="mapping-body " style={{ display: currentPage === 0 ? "flex" : "none" }}>
                                <div className="maped-box maped-box-commun">
                                    <div className="box-heding">
                                        <p>Mapped</p>
                                        <span>{mapedTallyTable.length}</span>
                                    </div>
                                    <div className="table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Tally Fields</th>
                                                    <th>Your Sheet Header</th>
                                                    <th>Your Sheet Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <SimpleBar style={{ height: 420 }}>
                                                    {mapedTallyTable.map((row, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{row}</td>
                                                                <td>
                                                                    <Select
                                                                        style={{ width: "100%" }}
                                                                        id="mappingColumn"
                                                                        value={mapping[row] || ""}
                                                                        onChange={(event) => handleMappingChange(event, key)}
                                                                    >
                                                                        {mapedSheetTable.map((heading, key) => (
                                                                            <option key={key} value={heading}>
                                                                                {heading}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </td>
                                                                <td>{json[0][mapping[row]]}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </SimpleBar>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="maped-box maped-box-commun">
                                    <div className="box-heding">
                                        <p>Unmapped</p>
                                        <span>{unmapedTallyTable.length}</span>
                                    </div>
                                    <div className="table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Tally Fields</th>
                                                    <th>Your Sheet Header</th>
                                                    <th>Your Sheet Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <SimpleBar style={{ height: 420 }}>
                                                    {unmapedTallyTable.map((row, key) => (
                                                        <tr key={key}>
                                                            <td>{row}</td>
                                                            <td>
                                                                <Select
                                                                    disabled
                                                                    style={{ width: "100%" }}
                                                                    id="mappingColumn"
                                                                    value={unmapping[row] || ""}
                                                                    showSearch
                                                                    onChange={(event) => handleUnmappingChange(event, key)}
                                                                >
                                                                    {unmapedSheetTable.map((heading, key) => (
                                                                        <option key={key} value={heading}>
                                                                            {heading}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </td>
                                                            <td>{json[0][unmapping[row]]}</td>
                                                        </tr>
                                                    ))}
                                                </SimpleBar>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentPage === 1 && (
                            <div className="mapping-body" style={{ display: currentPage === 1 ? "block" : "none" }}>
                                <div className="excel-confirm">
                                    <div className="excel-confirm-btn">
                                        <label htmlFor="" className="mr-3">
                                            GST Ledger from excel sheet?
                                        </label>
                                        <Radio.Group value={gstFromExcel} onChange={handleExcelChange} buttonStyle="solid">
                                            <Radio.Button value="Yes">Yes</Radio.Button>
                                            <Radio.Button value="No">No</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <div className="excel-confirm-btn">
                                        <label htmlFor="" className="mr-3">
                                            GST Auto Calculation?
                                        </label>
                                        <Radio.Group value={gstAutoCalculation} onChange={handleAutoCalculationChange} buttonStyle="solid">
                                            <Radio.Button value="Yes">Yes</Radio.Button>
                                            <Radio.Button value="No">No</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </div>
                                <div className="maped-box-w-100 maped-box-commun">
                                    {gstFromExcel === "No" && (
                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Tax Ledge</th>
                                                        <th>Tally Fields</th>
                                                        <th>Your Sheet Data</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <SimpleBar style={{ height: 180 }}>
                                                        {secondPageTaxLedger.map((row, key) => (
                                                            <tr key={key}>
                                                                <td>{row}</td>
                                                                <td>
                                                                    <Select
                                                                        style={{ width: "100%" }}
                                                                        id="mappingColumn"
                                                                        value={ledgerSheetNo[row]}
                                                                        showSearch
                                                                        onChange={(event) => handleSecondPageTaxLedger(event, key, row)}
                                                                    >
                                                                        {ledgerUnderDutiesTaxes.map((heading, key) => (
                                                                            <option key={key} value={heading}>
                                                                                {heading}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </td>
                                                                <td>{row.column3}</td>
                                                            </tr>
                                                        ))}
                                                    </SimpleBar>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {gstFromExcel === "Yes" && (
                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Your Sheet Data</th>
                                                        <th>SGST Ledge</th>
                                                        <th>CGST Ledge</th>
                                                        <th>IGST Ledge</th>
                                                        <th>Do you want to add Narration?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <SimpleBar style={{ height: 180 }}>
                                                        {unmapedSheetTable.map((row, key) => (
                                                            <tr key={key}>
                                                                <td>{row}</td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-sgst-${key}`}
                                                                        checked={excelSheeSelectedCheckboxes["sgst ledger"] === row}
                                                                        onChange={(event) =>
                                                                            handleExcelSheeCheckboxChange(event, row, "sgst ledger")
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-cgst-${key}`}
                                                                        checked={excelSheeSelectedCheckboxes["cgst ledger"] === row}
                                                                        onChange={(event) =>
                                                                            handleExcelSheeCheckboxChange(event, row, "cgst ledger")
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-igst-${key}`}
                                                                        checked={excelSheeSelectedCheckboxes["igst ledger"] === row}
                                                                        onChange={(event) =>
                                                                            handleExcelSheeCheckboxChange(event, row, "igst ledger")
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>{row}</td>
                                                            </tr>
                                                        ))}
                                                    </SimpleBar>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {gstAutoCalculation === "No" && (
                                        <div className="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Your Sheet Data</th>
                                                        <th>SGST Ledge Amount</th>
                                                        <th>CGST Ledge Amount</th>
                                                        <th>IGST Ledge Amount</th>
                                                        <th>Do you want to add Narration?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <SimpleBar style={{ height: 180 }}>
                                                        {unmapedSheetTable.map((row, key) => (
                                                            <tr key={key}>
                                                                <td>{row}</td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-sgst-${key}`}
                                                                        checked={gstAutoSelectedCheckboxes["sgst"] === row}
                                                                        onChange={(event) =>
                                                                            handleGstAutoCheckboxChange(event, row, "sgst")
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-cgst-${key}`}
                                                                        checked={gstAutoSelectedCheckboxes["cgst"] === row}
                                                                        onChange={(event) =>
                                                                            handleGstAutoCheckboxChange(event, row, "cgst")
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`checkbox-igst-${key}`}
                                                                        checked={gstAutoSelectedCheckboxes["igst"] === row}
                                                                        onChange={(event) =>
                                                                            handleGstAutoCheckboxChange(event, row, "igst")
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>{row}</td>
                                                            </tr>
                                                        ))}
                                                    </SimpleBar>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <div className="table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="d-flex align-items-center">
                                                        <Checkbox
                                                            type="checkbox"
                                                            id="enableRoundOff"
                                                            checked={isRoundOffEnabled}
                                                            onChange={handleRoundOffCheckboxChange}
                                                        />
                                                        <p htmlFor="enableRoundOff" style={{ marginLeft: "8px" }}>
                                                            Enable Auto Round-Off
                                                        </p>
                                                    </th>
                                                    <th>Ledger</th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                            {isRoundOffEnabled && (
                                                <tbody>
                                                    <SimpleBar>
                                                        {roundOffTallyFild.map((row, key) => (
                                                            <tr key={key}>
                                                                <td>{row}</td>
                                                                <td>
                                                                    <Select
                                                                        style={{ width: "100%" }}
                                                                        id="mappingColumn"
                                                                        showSearch
                                                                        value={roundOFf?.isroundoff || "Select Ledger"}
                                                                        onChange={(event) => roundOFfChange(event, key)}
                                                                    >
                                                                        {ledgeroption.map((heading, key) => (
                                                                            <option key={key} value={heading}>
                                                                                {heading}
                                                                            </option>
                                                                        ))}
                                                                    </Select>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        ))}
                                                    </SimpleBar>
                                                </tbody>
                                            )}
                                        </table>
                                    </div>
                                </div>

                                <div className="excel-confirm"></div>
                            </div>
                        )}
                        {currentPage === 2 && (
                            <div className="mapping-body" style={{ display: currentPage === 2 ? "block" : "none" }}>
                                <div className="maped-box-w-100 maped-box-commun">
                                    <div className="box-heding">
                                        <p>Mapped</p>
                                        <span>18</span>
                                    </div>
                                    <div className="table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Tally Fields</th>
                                                    <th>Your Sheet Header</th>
                                                    <th>Your Sheet Data</th>
                                                    <th>Your Sheet Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <SimpleBar>
                                                    {initialUnmapedTable.map((row, key) => (
                                                        <tr key={key}>
                                                            <td>{row}</td>
                                                            <td>
                                                                <Select
                                                                    style={{ width: "100%" }}
                                                                    id="mappingColumn"
                                                                    value={initialUnmapedTable[key]}
                                                                    onChange={(event) => unmapedHandleChange(event, key)}
                                                                >
                                                                    {initialUnmapedTable.map((heading, key) => (
                                                                        <option key={key} value={heading}>
                                                                            {heading}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </td>
                                                            <td>{row.column3}</td>
                                                            <td>{row.column3}</td>
                                                        </tr>
                                                    ))}
                                                </SimpleBar>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mapping-footer-outer">
                        <div className="mapping-footer">
                            {currentPage !== 0 && (
                                <Button className="mapping-btn" type="primary" onClick={previousPage}>
                                    Previous
                                </Button>
                            )}
                            {currentPage !== 2 && (
                                <Button className="mapping-btn" type="primary" onClick={nextPage}>
                                    Next
                                </Button>
                            )}
                            {currentPage === 2 && (
                                <Button className="mapping-btn" type="primary" onClick={mappingarraymearge}>
                                    Save
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withProfile(Mapping);
